import { FC } from "react";
import { Link } from "react-router-dom";

import "scss/UserSettings.scss";

interface IMfaSetting {
    text: string;
    changeUrlRoute?: string;
    changeUrlFunc?: () => void;
}

const MfaSetting: FC<IMfaSetting> = ({ text, changeUrlRoute, changeUrlFunc }) => (
    <div className="mfa-setting">
        <span>{text}</span>
        {changeUrlRoute && changeUrlFunc ? (
            <Link className="user-settings-action-link" to={changeUrlRoute} onClick={changeUrlFunc}>
                Change
            </Link>
        ) : null}
    </div>
);

export default MfaSetting;
