import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Divider, Segment } from "semantic-ui-react";
import { UAParser } from "ua-parser-js";

import { history } from "App";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { ITrustedDevice, MFASetupStatus, trustedDeviceRemovalError } from "model/mfa";
import { IErrorResponse } from "model/errors";

import * as actions from "redux/actions";
import getMFASetupState from "redux/selectors/mfa";
import { getUser } from "redux/selectors/data";

import { CHANGE_MOBILE_NUMBER, CHANGE_PASSWORD } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

import MfaSetting from "./MfaSetting";
import TrustedDeviceRemovalConfirmation from "./TrustedDeviceRemovalConfirmation";

import "scss/UserSettings.scss";

const UserSettings: FC = () => {
    const [groupedDevices, setGroupedDevices] = useState(new Map<string, string[]>());
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const dispatch = useDispatch();

    const mfaState = useSelector(getMFASetupState);
    const userState = useSelector(getUser);

    const groupDevices = (devices: ITrustedDevice[]): Map<string, string[]> => {
        const deviceMap = new Map<string, string[]>();
        devices.forEach((device) => {
            const { os, browser } = UAParser(device.userAgent);
            const displayName = `${os.name} ${os.version} (${browser.name})`;
            const group = deviceMap.get(displayName);
            if (group?.length) {
                group.push(device.uuid);
            } else {
                deviceMap.set(displayName, [device.uuid]);
            }
        });
        return deviceMap;
    };

    useEffect(() => {
        const getTrustedDevices = async (): Promise<void> => {
            const fetchedDevices = await userService.getTrustedDevices();
            const deviceMap = groupDevices(fetchedDevices);
            setGroupedDevices(deviceMap);
        };
        if (mfaState.isEnabledForAnyOrganisation) {
            getTrustedDevices();
        }
    }, []);

    const mobileVerificationText = `Mobile phone authentication is set with the number ${mfaState?.sms?.destination}`;
    const emailVerificationText = `Email authentication is set with the email ${userState.email}`;

    const openConfirmation = (name: string): void => {
        setShowConfirmation(true);
        setSelectedGroup(name);
    };

    const closeConfirmation = (): void => {
        setShowConfirmation(false);
        setSelectedGroup(null);
    };

    const goToChangePassword = () => {
        history.push(CHANGE_PASSWORD);
    };

    const goToMobileManagement = () => {
        history.push(CHANGE_MOBILE_NUMBER);
        dispatch(actions.mfaActions.initMobileNumberChange());
    };

    const handleClick = () => {
        logoutTimeoutService.startCount();
    };

    const removeDevice = async (name: string): Promise<void> => {
        try {
            setApiErrorResponse(null);
            const result = groupedDevices.get(name).map(async (uuid) => userService.removeTrustedDevice(uuid));
            await Promise.all(result);
            groupedDevices.delete(name);
            setGroupedDevices(groupedDevices);
        } catch (error) {
            setApiErrorResponse({
                message: trustedDeviceRemovalError.message,
            });
        }
        setShowConfirmation(false);
        setSelectedGroup(null);
    };

    const groupedDevicesKeys = [...groupedDevices.keys()];

    return (
        <Container onClick={handleClick}>
            <Segment className="user-settings">
                <h1>Settings</h1>
                <h3>Password</h3>
                <Divider />
                <Link className="user-settings-link" to={CHANGE_PASSWORD} onClick={goToChangePassword}>
                    Change password
                </Link>
                {mfaState.isEnabledForAnyOrganisation ? (
                    <>
                        <h3>Multi-factor authentication</h3>
                        <Divider />
                        {mfaState?.sms?.status !== MFASetupStatus.VERIFIED &&
                        mfaState?.email?.status !== MFASetupStatus.VERIFIED ? (
                            <p>
                                You haven&apos;t set up your multi-factor authentication methods yet. Please do so the
                                next time you log in to keep your account secure.
                            </p>
                        ) : null}
                        {mfaState?.sms?.status === MFASetupStatus.VERIFIED && mfaState?.sms?.destination ? (
                            <MfaSetting
                                text={mobileVerificationText}
                                changeUrlRoute={CHANGE_MOBILE_NUMBER}
                                changeUrlFunc={goToMobileManagement}
                            />
                        ) : null}
                        {mfaState?.email?.status === MFASetupStatus.VERIFIED ? (
                            <MfaSetting text={emailVerificationText} />
                        ) : null}
                        <h3>Trusted devices</h3>
                        <Divider />
                        {apiErrorResponse ? (
                            <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
                        ) : null}
                        {groupedDevicesKeys.length
                            ? groupedDevicesKeys.map((device: string) => (
                                  <div key={device}>
                                      <span>{device}</span>
                                      <Link
                                          className="user-settings-action-link"
                                          to="#/"
                                          onClick={() => openConfirmation(device)}
                                      >
                                          Remove
                                      </Link>
                                  </div>
                              ))
                            : "-"}
                        <TrustedDeviceRemovalConfirmation
                            show={showConfirmation}
                            closeOnOverlay={false}
                            onConfirm={() => removeDevice(selectedGroup)}
                            onClose={closeConfirmation}
                        />
                    </>
                ) : null}
            </Segment>
        </Container>
    );
};

export default UserSettings;
