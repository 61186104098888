import { Component, Fragment, ChangeEvent, FormEvent } from "react";
import { CheckboxProps, Form, InputOnChangeData } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";

import FormElement from "components/templates/FormElement";
import HospitalNumberTip from "components/templates/HospitalNumberTip";
import NHSNumberTip from "components/templates/NHSNumberTip";

import { isUndefined } from "helpers/common";

import QuestionType from "model/questionType";
import { UserRole } from "model/userRole";
import {
    IDefinedPersonalData,
    IOrganisation,
    IDefinedValidationType,
    IDefinedPropertyName,
    DefinedPersonalDataType,
} from "model/organisation";
import { IPatientEdit } from "model/patientEdit";
import PatientDetailsIntegration from "model/integrations";

import "scss/InputField.scss";

interface IPatientDetailsEditProps {
    patient: IPatientEdit;
    personalDataFields: IDefinedPersonalData[];
    organisation: IOrganisation;
    errors: any;
    editMode: boolean;
    patientCreated: boolean;
    changeFieldValue: (fieldName: string, fieldValue: any, errors?: any) => void;
    isAtHomeFlow?: boolean;
    userRole?: UserRole;
}

interface LinkState {
    from: string;
    defaultPDSSearchCombo: number;
    values: {
        birth: string | null;
        [key: string]: any;
    };
}

interface LinkDescriptor {
    pathname: string;
    state: LinkState;
}

class PatientDetailsEdit extends Component<IPatientDetailsEditProps> {
    private changeFieldValue = (
        event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>,
        data: InputOnChangeData | CheckboxProps
    ) => {
        const { changeFieldValue } = this.props;
        const { type, value: dataValue, checked, name, id } = data;

        let value;
        if (type === DefinedPersonalDataType.NUMBER) {
            value = +dataValue;
        } else {
            value = dataValue || checked;
        }

        const propertyName = type === QuestionType.RADIO ? name : id;
        changeFieldValue(propertyName, value);
    };

    private getFieldValue = (field: IDefinedPersonalData, patient: IPatientEdit) => {
        const { propertyName } = field;

        if (patient && !isUndefined(patient[propertyName])) {
            return patient[propertyName];
        }

        return "";
    };

    private getLinkToPDSSearchComboTwo = (): LinkDescriptor => {
        const patientData = this.props.patient;
        return {
            pathname: "/patient-lookup",
            state: {
                from: "patient-details",
                defaultPDSSearchCombo: 2,
                values: {
                    ...patientData,
                    birth: patientData.birth ? moment(patientData.birth).format("YYYY-MM-DD") : null,
                },
            },
        };
    };

    public render() {
        const { personalDataFields, editMode, patient, errors, patientCreated, isAtHomeFlow, organisation, userRole } =
            this.props;
        const isBack = patientCreated && !editMode;
        const isPDSOrg = organisation?.patientDetailsIntegrationType === PatientDetailsIntegration.PDS;

        return (
            <Form>
                {personalDataFields.map((field: IDefinedPersonalData) => {
                    const { required, validationProperty, propertyName, uuid } = field;
                    const value = this.getFieldValue(field, patient);

                    const isNhsRequired = validationProperty === IDefinedValidationType.nhsNumber;
                    const isHospitalNumberField = propertyName === IDefinedPropertyName.hospitalNumber;
                    const isNHSNumberField = propertyName === IDefinedPropertyName.nhsNumber;
                    const isNonZeroNhsNumber = value !== "000 000 0000";

                    const showNhsNumberValidationMessage =
                        isPDSOrg &&
                        isAtHomeFlow &&
                        userRole === UserRole.CLINICIAN &&
                        isNHSNumberField &&
                        !isNonZeroNhsNumber;

                    return (
                        <Fragment key={uuid}>
                            {isAtHomeFlow && isNhsRequired && !isPDSOrg ? (
                                <p>Please make sure the NHS No and Hospital No are correct before continuing.</p>
                            ) : null}
                            <FormElement
                                formErrors={errors}
                                disabled={isBack}
                                required={required}
                                data={field}
                                onChange={this.changeFieldValue}
                                value={value}
                                validationProperty={validationProperty}
                                className="field patient"
                            />
                            {showNhsNumberValidationMessage ? (
                                <p style={{ color: "#C90002", marginTop: "-10px" }}>
                                    Provide a valid NHS number before proceeding or{" "}
                                    <Link
                                        to={() => this.getLinkToPDSSearchComboTwo()}
                                        style={{ textDecoration: "underline" }}
                                    >
                                        use alternative patient details
                                    </Link>{" "}
                                    to verify the patient
                                </p>
                            ) : null}
                            {isAtHomeFlow &&
                            isPDSOrg &&
                            field.integrationSourceSystem !== PatientDetailsIntegration.PDS ? (
                                <p className="input-info-message">
                                    {`Ensure the ${field.displayName} is correct. We will not be able to verify it against the NHS database`}
                                </p>
                            ) : null}
                            {isNhsRequired && !isBack && <NHSNumberTip />}
                            {isHospitalNumberField && !isBack && <HospitalNumberTip />}
                        </Fragment>
                    );
                })}
            </Form>
        );
    }
}

export default PatientDetailsEdit;
