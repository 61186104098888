import { FC, useState } from "react";
import { useDispatch } from "react-redux";

import VerifyOTP from "components/MFA/VerifyOTP";
import InterstitialPage from "components/templates/InterstitialPage";

import { MFAVerificationChannel } from "model/mfa";
import { homeScreenInitialisation } from "services/authorizationService";

const MFAAuthentication: FC = () => {
    const dispatch = useDispatch();
    const [channel, setChannel] = useState<MFAVerificationChannel>(MFAVerificationChannel.EMAIL);

    const switchChannel = (): void => {
        setChannel(
            channel === MFAVerificationChannel.EMAIL ? MFAVerificationChannel.SMS : MFAVerificationChannel.EMAIL
        );
    };

    const handleVerification = (): void => {
        homeScreenInitialisation(dispatch);
    };

    return (
        <InterstitialPage>
            <VerifyOTP
                showTrustedDeviceCheckbox
                showVerificationChannelSwitch
                title="Let's start by verifying your email"
                channel={channel}
                switchChannel={switchChannel}
                handleVerification={handleVerification}
            />
        </InterstitialPage>
    );
};

export default MFAAuthentication;
