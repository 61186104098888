import { Component } from "react";
import { Link } from "react-router-dom";
import { Button, SemanticFLOATS } from "semantic-ui-react";

import "scss/Button.scss";

interface ICustomButton {
    action?: any;
    variant: "empty" | "filled" | "empty-dark" | "dark" | "warning" | "cancel";
    type: "submit" | "link" | "button";
    to?: string;
    text: string;
    icon?: any;
    loading?: any;
    size?: "small" | "medium";
    disabled?: boolean;
    floated?: SemanticFLOATS;
    className?: string;
    dataTestId?: string;
    ariaLabel?: string;
}

class CustomButton extends Component<ICustomButton, any> {
    private getLinkButton() {
        const { variant, size, to, loading, action, disabled, icon, text, floated, className, dataTestId, ariaLabel } =
            this.props;

        const styleClass = `ui button ${variant}-button ${size || ""} ${className || ""}`;

        return (
            <Button
                tabIndex={0}
                as={Link}
                to={to}
                color="teal"
                className={styleClass}
                loading={loading}
                onClick={action}
                disabled={disabled}
                icon={icon}
                content={text}
                floated={floated}
                data-testid={dataTestId}
                aria-label={ariaLabel}
            />
        );
    }

    private getSubmitButton() {
        const {
            type,
            variant,
            size,
            className,
            disabled,
            loading,
            action,
            icon,
            text,
            floated,
            dataTestId,
            ariaLabel,
        } = this.props;

        const styleClass = `ui button ${variant}-button ${size || ""} ${className || ""}`;
        return (
            <Button
                tabIndex={0}
                className={styleClass}
                disabled={disabled}
                type={type === "link" ? undefined : type} // Only set the type if it's not "link"
                loading={loading}
                onClick={action}
                icon={icon}
                content={text}
                floated={floated}
                data-testid={dataTestId}
                aria-label={ariaLabel}
            />
        );
    }

    public render() {
        const { type } = this.props;

        if (type === "submit" || type === "button") {
            return this.getSubmitButton();
        }
        if (type === "link") {
            return this.getLinkButton();
        }

        return undefined;
    }
}

export default CustomButton;
