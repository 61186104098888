import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { handleKeyDown } from "helpers/input";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";
import InterstitialPage from "components/templates/InterstitialPage";

import { MFAButtons } from "model/mfa";

import * as ROUTES from "navigation/routes";
import { history } from "App";

import * as actions from "redux/actions";

import { homeScreenInitialisation } from "services/authorizationService";

import "scss/MFASetup.scss";

const TrustedDeviceExpiration = () => {
    const dispatch = useDispatch();

    const handleSkipMFA = (): void => {
        dispatch(actions.mfaActions.mfaCompleteSetup());
        homeScreenInitialisation(dispatch);
    };

    const handleStart = (): void => {
        history.push(ROUTES.MFA_AUTHENTICATION);
    };

    return (
        <InterstitialPage>
            <Box>
                <Box display="flex" alignContent="center">
                    <img className="mfa-setup-icon" alt="MFA setup icon" src="/images/MFASetupTrustedDevice.png" />
                </Box>
                <Box>
                    <h2>Your trusted device certificate will expire soon</h2>
                    <Box>
                        <p>
                            To keep your account safe, we need to authenticate your identity every 90 days if you are
                            using a trusted device.
                        </p>
                        <CustomMessage
                            type={CustomMessageType.INFO}
                            message="You will need access to you email or mobile phone to authenticate your identity"
                        />
                    </Box>

                    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={2}>
                        <Box marginBottom={2} marginTop={2} width="100%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="primary"
                                onClick={handleStart}
                                onKeyDown={(e) => handleKeyDown(e, handleStart)}
                            >
                                {MFAButtons.AUTHENTICATE_NOW}
                            </Button>
                        </Box>
                        <Box
                            className="text-button text-button__heavy text-button__heavy--blue"
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => handleKeyDown(e, handleStart)}
                            onClick={handleSkipMFA}
                        >
                            Skip for now (14 days until this is required)
                        </Box>
                    </Box>
                </Box>
            </Box>
        </InterstitialPage>
    );
};

export default TrustedDeviceExpiration;
