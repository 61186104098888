import { FC, memo } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import useCountdownTimer from "hooks/useCountdownTimer";

import { MFAVerificationChannel, MFAUserFriendlyChannel } from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";

import "scss/MFASetup.scss";

interface ICountdown {
    channel: MFAVerificationChannel;
    setHasErrored: (boolean) => void;
    initiateNewOTP: () => void;
}

const Countdown: FC<ICountdown> = memo(({ channel, initiateNewOTP, setHasErrored }) => {
    const loading = useSelector(getPendingRequest);
    const { countdownTime, isCountdownFinished, resetCountdown } = useCountdownTimer();

    const requestNewOTP = async (): Promise<void> => {
        try {
            resetCountdown();
            initiateNewOTP();
        } catch (err) {
            setHasErrored(true);
        }
    };

    const formattedCountdownTime = String(countdownTime).padStart(2, "0");
    const resendBase = `Resend ${MFAUserFriendlyChannel[channel]}`;
    const resendText = `${isCountdownFinished ? resendBase : `${resendBase} in 00:${formattedCountdownTime}`}`;
    return (
        <Box className="flex-center">
            <p>
                Didn&apos;t get the code?&nbsp;
                <Box
                    component="span"
                    className={clsx(
                        "text-button",
                        "text-button__heavy",
                        countdownTime > 0
                            ? "text-button--button-inactive text-button__heavy--grey"
                            : "text-button__heavy--blue"
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={countdownTime === 0 ? requestNewOTP : null}
                >
                    {loading ? null : resendText}
                </Box>
            </p>
        </Box>
    );
});

export default Countdown;
