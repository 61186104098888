import { FC } from "react";
import Box from "@material-ui/core/Box";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";

import { SUPPORT_EMAIL } from "model/common";

import "scss/CustomMessage.scss";

export enum CustomMessageType {
    INFO = "info",
    ERROR = "error",
}

export type CustomMessageDetails = Omit<ICustomMessage, "type">;

export interface ICustomMessage {
    message: string;
    showDefaultHelp?: boolean;
    type: CustomMessageType;
    cta?: string;
}

const CustomMessage: FC<ICustomMessage> = ({ cta, message, showDefaultHelp, type }) => (
    <Box className={clsx("custom-message", type)}>
        <ErrorOutlineIcon fontSize="large" />

        <Box marginLeft={2}>
            <Box
                className={clsx("message-title", !cta && "message-title__only")}
                role="alert"
                aria-live="polite"
                aria-label={`${message} ${cta || ""}`}
            >
                <p>{message}</p>
            </Box>
            <Box>
                {cta ? <p>{cta}</p> : null}
                {showDefaultHelp ? (
                    <p>
                        If you continue having trouble completing the process, contact our support team at:&nbsp;
                        <a href={`mailto:${SUPPORT_EMAIL}`} aria-label={`Send email to ${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}.
                        </a>
                    </p>
                ) : null}
            </Box>
        </Box>
    </Box>
);

CustomMessage.defaultProps = {
    showDefaultHelp: false,
    cta: null,
};

export default CustomMessage;
