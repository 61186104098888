import { FC } from "react";
import * as actions from "redux/actions";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { MFAButtons } from "model/mfa";

import { handleKeyDown } from "helpers/input";

import "scss/MFASetup.scss";
import { homeScreenInitialisation } from "services/authorizationService";

interface IUntrustedDeviceGuidance {
    handleBack: () => void;
}

const UntrustedDeviceGuidance: FC<IUntrustedDeviceGuidance> = ({ handleBack }) => {
    const dispatch = useDispatch();

    const goBack = async (): Promise<void> => {
        handleBack();
    };
    const doNotTrustDevice = (): void => {
        dispatch(actions.mfaActions.mfaCompleteSetup());
        homeScreenInitialisation(dispatch);
    };

    return (
        <>
            <Box>
                <Box display="flex" alignContent="center">
                    <img className="mfa-setup-icon" alt="MFA setup icon" src="/images/MFASetupComplete.png" />
                </Box>
                <Box>
                    <h2>Are you sure you don&apos;t want to trust this device?</h2>
                    <Box>
                        <p>
                            Trusting this device means you won&apos;t need to enter a verification code for the next 90
                            days. This provides a smoother and faster login experience while maintaining security.
                        </p>
                    </Box>

                    <Box display="flex" flexDirection="row" paddingTop={2}>
                        <Box marginRight={0.5} width="50%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="secondary"
                                onClick={goBack}
                                onKeyDown={(e) => handleKeyDown(e, goBack)}
                            >
                                {MFAButtons.TRUST_DEVICE}
                            </Button>
                        </Box>
                        <Box marginLeft={0.5} width="50%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="primary"
                                onClick={doNotTrustDevice}
                                onKeyDown={(e) => handleKeyDown(e, doNotTrustDevice)}
                            >
                                {MFAButtons.DO_NOT_TRUST_DEVICE}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default UntrustedDeviceGuidance;
