import { FC } from "react";
import { useSelector } from "react-redux";
import { Container, Divider } from "semantic-ui-react";

import CaseList from "components/CaseList";
import OrganisationSelection from "components/OrganisationSelection/OrganisationSelection";
import ConditionalRender from "components/templates/ConditionalRender";

import { UserRole } from "model/userRole";

import { getShowOrganisationSelection } from "redux/selectors/data";

import logoutTimeoutService from "services/logoutTimeoutService";

import "scss/Page.scss";

const HomeScreen: FC = () => {
    const showOrganisationSelection = useSelector(getShowOrganisationSelection);
    const handleClick = () => {
        logoutTimeoutService.startCount();
    };

    return (
        <Container className="home-screen-wrapper" onClick={handleClick}>
            {showOrganisationSelection ? (
                <OrganisationSelection />
            ) : (
                <>
                    <Divider />
                    <ConditionalRender
                        requiredRole={[
                            UserRole.SUPERADMIN,
                            UserRole.PATIENT,
                            UserRole.CLINICIAN,
                            UserRole.ADMIN,
                            UserRole.DERMATOLOGIST,
                            UserRole.SA_ADMIN,
                            UserRole.CALLBACK_AGENT,
                            UserRole.CLINICAL_VIEWER,
                        ]}
                    >
                        <CaseList />
                    </ConditionalRender>
                    <Divider />
                </>
            )}
        </Container>
    );
};

export default HomeScreen;
