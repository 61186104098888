import { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

interface IMobileNumberChanged {
    onClose: () => void;
}

const MobileNumberChanged: FC<IMobileNumberChanged> = ({ onClose }) => (
    <div>
        <h3 className="mobile-number-changed-title">Your mobile number has been updated succesfully</h3>
        <Image className="mobile-number-changed-icon" src="/images/icons/icon-round-check.svg" />
        <CustomButton
            className="mobile-number-close-button"
            type="button"
            variant="filled"
            action={onClose}
            text="Close"
        />
    </div>
);

export default MobileNumberChanged;
