import { FC } from "react";
import { Route, Switch } from "react-router-dom";

import PageContainer from "components/templates/PageContainer";
import Administration from "components/Administration/AdministrationScreen";
import ClinicLocation from "components/ClinicLocation";
import NewAssessment from "components/NewAssessment";
import PatientDetails from "components/PatientDetails";
import PatientLookup from "components/PatientLookup";
import LesionLocator from "components/LesionLocator";
import ImageCapture from "components/ImageCapture";
import ImageManualUpload from "components/ImageManualUpload";
import MedicalHistoryQuestions from "components/MedicalHistoryQuestions";
import FitzpatrickHistoryQuestion from "components/FitzpatrickHistoryQuestion";
import StudyImageCapture from "components/StudyImageCapture";
import ReadyForAssessment from "components/ReadyForAssessment";
import StudyAssessmentSummary from "components/StudyAssessmentSummary";
import AiResults from "components/AiResults";
import ChangePassword from "components/ChangePassword";
import ResetPassword from "components/ResetPassword";
import ImmediateResult from "components/ImmediateResult";
import InitialDiagnosis from "components/InitialDiagnosis";
import UpdateDiagnosis from "components/UpdateDiagnosis";
import UploadedImages from "components/UploadedImages";
import GuidancePage from "components/GuidancePage";
import AssessmentMode from "components/AssessmentMode";
import CsvDownloadReportPage from "components/CsvDownloadReportPage";
import PatientSearch from "components/PatientSearch";
import ScrollToTop from "components/ScrollToTop";
import TestPage from "components/TestPage";
import UserSettings from "components/UserSettings/UserSettings";

import { UserRole } from "model/userRole";

import * as ROUTES from "navigation/routes";
import * as REMOTE_MODEL_ROUTES from "navigation/remoteModelRoutes";
import CaseListRoutes from "navigation/CaseListRoutes";
import PatientRoutes from "navigation/PatientRoutes";

import userService from "services/userService";
import PatientSearchProvider from "contextProviders/PatientSearchProvider";
import MobileManagement from "components/UserSettings/MobileManagement";

const PrivateRoutes: FC = () => {
    const isCurrentUserPatient = userService.checkUserHasRole([UserRole.PATIENT]);

    return (
        <PageContainer isPublic={false}>
            <ScrollToTop />
            <Switch>
                <Route path={`${ROUTES.ADMINISTRATION}/:page?/:subpage?/:uuid?`} component={Administration} exact />
                <Route path={ROUTES.CLINIC_LOCATION} component={ClinicLocation} />
                <Route path={ROUTES.NEW_ASSESSMENT} component={NewAssessment} />
                <Route path={ROUTES.PATIENT_DETAILS} component={PatientDetails} />
                <Route path={ROUTES.PATIENT_LOOK_UP} component={PatientLookup} />
                <Route path={ROUTES.PATIENT_SEARCH}>
                    <PatientSearchProvider>
                        <PatientSearch />
                    </PatientSearchProvider>
                </Route>
                <Route path={`${ROUTES.LESION_LOCATOR}/:lesionNumber?`} component={LesionLocator} />
                <Route path={ROUTES.IMAGE_CAPTURE} component={ImageCapture} />
                <Route path={ROUTES.IMAGE_MANUAL_UPLOAD} component={ImageManualUpload} />
                <Route path={ROUTES.MEDICAL_HISTORY} component={MedicalHistoryQuestions} exact />
                <Route path={ROUTES.SUN_EXPOSURE} component={FitzpatrickHistoryQuestion} exact />
                <Route path={ROUTES.STUDY_IMAGE_CAPTURE} component={StudyImageCapture} />
                <Route path={ROUTES.READY_FOR_ASSESSMENT} component={ReadyForAssessment} />
                <Route path={ROUTES.STUDY_ASSESSMENT_SUMMARY} component={StudyAssessmentSummary} />
                <Route path={ROUTES.AI_RESULTS} component={AiResults} />
                <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
                <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
                <Route path={`${ROUTES.CASE_RESULTS}/:id`} component={ImmediateResult} />
                <Route path={ROUTES.DIAGNOSIS} component={InitialDiagnosis} />
                <Route path={ROUTES.UPDATE_DIAGNOSIS} component={UpdateDiagnosis} />
                <Route path={ROUTES.UPLOADED_IMAGES} component={UploadedImages} />
                <Route path={ROUTES.GUIDANCE} component={GuidancePage} />
                <Route path={ROUTES.ASSESSMENT_MODE} component={AssessmentMode} />
                <Route path={ROUTES.USER_SETTINGS} component={UserSettings} />
                <Route path={ROUTES.CHANGE_MOBILE_NUMBER} component={MobileManagement} />
                <Route path={ROUTES.TEST} component={TestPage} />
                <Route path={`${ROUTES.CSV_REPORT}/:id/:filename`} component={CsvDownloadReportPage} />
                {isCurrentUserPatient && <Route path={REMOTE_MODEL_ROUTES.PATIENT_SUBPATH} component={PatientRoutes} />}
                <Route component={CaseListRoutes} />
            </Switch>
        </PageContainer>
    );
};

export default PrivateRoutes;
