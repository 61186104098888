import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

interface ITrustedDeviceRemovalConfirmation {
    onClose: () => void;
    onConfirm: () => void;
}

const TrustedDeviceRemovalConfirmation: FC<ITrustedDeviceRemovalConfirmation> = ({ onClose, onConfirm }) => (
    <div>
        <Image className="remove-trusted-device-confirmation-icon" src="/images/icons/icon-round-check.svg" />
        <h3 className="remove-trusted-device-confirmation-title">Remove trusted device?</h3>
        <p className="remove-trusted-device-confirmation-text">
            You will need to authenticate your identity via email or phone next time you log in.
        </p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="cancel" action={onClose} text="No, keep it" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="Yes, remove it" />
        </div>
    </div>
);

export default withModal(TrustedDeviceRemovalConfirmation);
