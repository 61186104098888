import { FC } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch } from "react-redux";

import { handleKeyDown } from "helpers/input";

import { MFAButtons } from "model/mfa";

import * as actions from "redux/actions";

import "scss/StepIndicator.scss";

interface IStepIndicator {
    allowBack: boolean;
    allowSkip: boolean;
    step: number;
    stepCount: number;
    handleBack: () => void;
}

const StepIndicator: FC<IStepIndicator> = ({ allowBack, allowSkip, step, stepCount, handleBack }) => {
    const dispatch = useDispatch();

    if (!step || step <= 0 || step > stepCount) {
        return null;
    }

    const handleSkip = async (): Promise<void> => {
        dispatch(actions.mfaActions.mfaCompleteSetup());
    };

    return (
        <Box className="step-indicator">
            {allowBack ? (
                <Box
                    className="back-button text-button text-button__no-underline text-button__heavy text-button__heavy--grey"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => handleKeyDown(e, handleBack)}
                    onClick={handleBack}
                >
                    {MFAButtons.BACK}
                </Box>
            ) : null}
            <div
                className="step-count"
                role="alert"
                aria-atomic
                aria-live="polite"
                aria-label={`Step ${step} of ${stepCount}`}
            >
                {step}/{stepCount}
            </div>

            {allowSkip ? (
                <Box
                    className="skip-button text-button text-button__no-underline text-button__heavy text-button__heavy--grey"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => handleKeyDown(e, handleSkip)}
                    onClick={handleSkip}
                >
                    {MFAButtons.SKIP}
                </Box>
            ) : null}
        </Box>
    );
};

export default StepIndicator;
