import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { history } from "App";

import PhoneNumberRegistration from "components/MFA/PhoneNumberRegistration";
import InterstitialPage from "components/templates/InterstitialPage";
import VerifyOTP from "components/MFA/VerifyOTP";

import { MFAVerificationChannel } from "model/mfa";

import getMFASetupState from "redux/selectors/mfa";
import * as actions from "redux/actions";

import { USER_SETTINGS } from "navigation/routes";

import MobileNumberChanged from "./MobileNumberChanged";

const MobileManagement: FC = () => {
    const dispatch = useDispatch();
    const mfaSetupState = useSelector(getMFASetupState);
    const { step } = mfaSetupState;

    const smsVerificationTitle = "Verify your mobile number";

    const goToUserSettings = () => {
        history.push(USER_SETTINGS);
    };

    const handleNext = (): void => {
        dispatch(actions.mfaActions.incrementStep());
    };

    return (
        <InterstitialPage>
            {step === 2 ? <PhoneNumberRegistration /> : null}
            {step === 3 ? (
                <VerifyOTP
                    channel={MFAVerificationChannel.SMS}
                    title={smsVerificationTitle}
                    handleVerification={handleNext}
                />
            ) : null}
            {step === 4 ? <MobileNumberChanged onClose={goToUserSettings} /> : null}
        </InterstitialPage>
    );
};

export default MobileManagement;
