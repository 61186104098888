import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import Questionary from "components/Questionary";
import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import PatientBanner from "components/templates/PatientBanner";

import logoutTimeoutService from "services/logoutTimeoutService";

import { doesOrganisationHaveLocations, scrollToError } from "helpers/assessment";
import { generateAnswers, mapStateToAnswers } from "helpers/questionary";
import {
    mapFitzpatrickQuestionAnswer,
    wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation,
} from "helpers/fitzpatrick";

import { IAssessment, IMedicalHistory, IMedicalHistoryAnswers } from "model/assessment";
import { IQuestionaryError } from "model/questionaryError";
import { IMedicalHistoryQuestion, IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";
import QuestionSubTypeEnum from "model/questionSubType";

import {
    CLINIC_LOCATION,
    HOME,
    LESION_LOCATOR,
    PATIENT_DETAILS,
    PATIENT_LOOK_UP,
    SUN_EXPOSURE,
} from "navigation/routes";

const PATIENT_CREATED_MODAL_VISIBILITY_TIME = 3000;

interface IMedicalHistoryQuestions {
    assessment: IAssessment;
    currentLesion: number;
    organisation: IOrganisation;
    pendingRequest: boolean;
    createMedicalHistory: (medicalHistoryAnswers?: any[]) => void;
    toggleShowIntegrationPatientCreatedModal: () => void;
}

interface IMedicalHistoryState {
    isBack: boolean;
    saveAssessment: boolean;
    medicalHistoryAnswers: { [uuid: string]: any };
    next: boolean;
    [x: number]: any;
    errors: IQuestionaryError;
    showModal: boolean;
    isEditMode: boolean;
    isEdited: boolean;
    hasFitzpatrickQuestion: boolean;
}

class MedicalHistoryQuestions extends Component<IMedicalHistoryQuestions, IMedicalHistoryState> {
    constructor(props: any) {
        super(props);

        const { assessment, organisation } = this.props;

        this.state = {
            errors: {
                formError: false,
                isInitial: true,
                list: [],
                showError: false,
            },
            isBack: Boolean(assessment?.patient?.medicalHistory?.length),
            isEditMode: false,
            isEdited: false,
            medicalHistoryAnswers: {},
            next: false,
            saveAssessment: false,
            showModal: false,
            hasFitzpatrickQuestion: Boolean(
                mapFitzpatrickQuestionAnswer({
                    medicalHistoryAnswers: assessment?.patient?.medicalHistory,
                    medicalHistoryQuestions: organisation?.medicalHistoryQuestions,
                })?.question
            ),
        };
    }

    public componentDidMount() {
        logoutTimeoutService.stopCount();
        this.initializeAnswers();
        const {
            assessment: { showIntegrationPatientCreatedModal },
            toggleShowIntegrationPatientCreatedModal,
        } = this.props;
        if (showIntegrationPatientCreatedModal) {
            setTimeout(() => {
                toggleShowIntegrationPatientCreatedModal();
            }, PATIENT_CREATED_MODAL_VISIBILITY_TIME);
        }
    }

    public componentDidUpdate(prevProps: IMedicalHistoryQuestions, prevState: IMedicalHistoryState) {
        const { errors } = this.state;

        if (prevState.errors !== errors && errors.list.length && errors.showError) {
            scrollToError({ errorUuid: errors.list[0] });
        }
    }

    private getBiopsyQuestions(medicalHistory: IMedicalHistory[]): IMedicalHistoryQuestion[] {
        const { organisation } = this.props;
        const medicalHistoryAnswersUuids = medicalHistory
            ? medicalHistory.filter((answer) => answer.answer || answer.answers.length).map((answer) => answer.uuid)
            : [];
        if (!organisation.biopsyQuestions) {
            return [];
        }
        const biopsyQuestions = medicalHistoryAnswersUuids.length
            ? organisation.biopsyQuestions.filter((question) => medicalHistoryAnswersUuids.includes(question.uuid))
            : organisation.biopsyQuestions.filter((question) => !question.removed);
        return biopsyQuestions;
    }

    public updateAnswers = (answers: any) => {
        this.setState({ medicalHistoryAnswers: answers, isEdited: true });
    };

    public updateErrors = (error: IQuestionaryError) => {
        this.setState({ errors: error });
    };

    public submit = ({ mainData }: { mainData: IMedicalHistoryAnswers[]; extraData: IMedicalHistoryAnswers[] }) => {
        const { errors, isBack, isEditMode, isEdited } = this.state;
        const { pendingRequest, createMedicalHistory } = this.props;
        const isValid = !errors.list.length && !errors.isInitial;
        this.setState({ errors: { ...errors, showError: true } });
        if (!isBack && !pendingRequest && isValid && !isEditMode) {
            createMedicalHistory(mainData);
            this.moveForward();
        }
        if (isEditMode && isEdited) {
            this.setState({
                showModal: true,
            });
        }
        if (!isEdited) {
            this.moveForward();
        }
    };

    public saveCase = ({ mainData }: { mainData: any }) => {
        const { errors, isBack, isEditMode, isEdited } = this.state;
        const { pendingRequest, createMedicalHistory } = this.props;
        const isValid = !errors.list.length && !errors.isInitial;
        if (!isBack && !pendingRequest && isValid) {
            createMedicalHistory(mainData);
            this.setState({
                saveAssessment: true,
            });
        }
        if (isEditMode && isEdited) {
            this.setState({ showModal: true });
        }
    };

    private editMedicalHistory = () => {
        const { isEditMode, errors, showModal, isEdited } = this.state;
        const { createMedicalHistory, pendingRequest } = this.props;
        if (isEditMode) {
            const isValid = !errors.list.length && !errors.isInitial;
            this.setState({ errors: { ...errors, showError: true } });
            if (!pendingRequest && isValid) {
                const { medicalHistoryAnswers } = this.state;
                const { mainData } = { ...mapStateToAnswers(medicalHistoryAnswers) };
                if (isEdited) {
                    createMedicalHistory(mainData);
                }
                this.setState({ isEditMode: false, isEdited: false });
                if (showModal) {
                    this.moveForward();
                }
            }
        } else {
            this.setState({ isEditMode: true });
        }
    };

    private closeModal = () => {
        this.setState({ showModal: false });
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = () => {
        this.initializeAnswers();
    };

    private initializeAnswers = () => {
        const { organisation, assessment } = this.props;
        const { medicalHistory } = assessment.patient;
        const { biopsyQuestions, medicalHistoryQuestions, nscMedicalHistoryQuestions } = organisation;
        const questionSubTypesToFilter = [QuestionSubTypeEnum.SKIN_TYPE];
        const medicalHistoryWithoutFitzpatrickQuestion = medicalHistoryQuestions.filter(
            (question) => !questionSubTypesToFilter.includes(question.questionSubType)
        );
        const isNonSkinCancer = assessment.case.nonSkinCancer;
        if (medicalHistory) {
            const questions = [
                ...((!isNonSkinCancer && biopsyQuestions) || []),
                ...((!isNonSkinCancer && medicalHistoryWithoutFitzpatrickQuestion) || []),
                ...((isNonSkinCancer && nscMedicalHistoryQuestions) || []),
            ].sort((a, b) => a.questionOrder - b.questionOrder);
            const answers = generateAnswers(medicalHistory, questions);
            this.setState({
                errors: {
                    formError: false,
                    isInitial: false,
                    list: [],
                    showError: true,
                },
                isEdited: false,
                medicalHistoryAnswers: answers,
            });
        }
    };

    public render() {
        const { assessment, organisation } = this.props;
        const { showModal, saveAssessment, next, hasFitzpatrickQuestion } = this.state;
        const {
            patient: { medicalHistory },
        } = assessment;

        if (saveAssessment && medicalHistory) {
            return <Redirect to={HOME} />;
        }

        const {
            case: currentCase,
            nonSkinCancer: isNonSkinCancerFlow,
            showIntegrationPatientCreatedModal,
            patient,
        } = assessment;
        const { lesions, caseId } = currentCase;
        const { integrationName } = this.props.assessment.patient?.integrationInfo || {};
        const organisationHasLocations = doesOrganisationHaveLocations(organisation.locations);
        const lesionLocatorUrl = `${LESION_LOCATOR}${lesions?.length ? "/1" : ""}`;
        const backButtonLink = integrationName === PatientDetailsIntegration.PDS ? PATIENT_LOOK_UP : PATIENT_DETAILS;

        const hasFitzpatrickAnswer = Boolean(
            mapFitzpatrickQuestionAnswer({
                medicalHistoryAnswers: assessment?.patient?.medicalHistory,
                medicalHistoryQuestions: organisation?.medicalHistoryQuestions,
            })?.questionAnswer
        );

        // Handle the case where the caseObject was just created and has no creationDate yet
        const caseObject = assessment.case;
        if (!caseObject.creationDate) {
            caseObject.creationDate = new Date().toISOString();
        }

        const isInflightCase = wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation({
            caseData: caseObject,
            organisation,
        });

        if (
            medicalHistory &&
            next &&
            hasFitzpatrickQuestion &&
            assessment.case.homeInitiated &&
            !hasFitzpatrickAnswer
        ) {
            return <Redirect to={organisationHasLocations ? CLINIC_LOCATION : lesionLocatorUrl} />;
        }

        if (medicalHistory && next && hasFitzpatrickQuestion && !isInflightCase) {
            return <Redirect to={SUN_EXPOSURE} />;
        }

        if (medicalHistory && next && hasFitzpatrickQuestion && isInflightCase) {
            return <Redirect to={organisationHasLocations ? CLINIC_LOCATION : lesionLocatorUrl} />;
        }

        if (medicalHistory && next && !hasFitzpatrickQuestion) {
            return <Redirect to={organisationHasLocations ? CLINIC_LOCATION : lesionLocatorUrl} />;
        }

        const assessmentHistoryQuestions = isNonSkinCancerFlow
            ? organisation.nscMedicalHistoryQuestions
            : organisation.medicalHistoryQuestions;

        const questionAnswers = assessment?.patient?.medicalHistory;

        /**
         * if medicalHistory -> this means that there are already some answers for some of the questions
         *      we filter out all the questions that are marked as removed and have no question answer
         *  else -> this means that this is a new case
         *      we remove all the organisation questions that are marked as removed
         */

        const allMedicalHistoryQuestions = medicalHistory?.length
            ? assessmentHistoryQuestions.filter((question) =>
                  questionAnswers.some((answer) => answer.uuid === question.uuid)
              )
            : assessmentHistoryQuestions.filter((question: IMedicalHistoryQuestion) => !question.removed);

        const medicalHistoryQuestionsWithoutFitzpatrick = allMedicalHistoryQuestions.filter(
            (question: IMedicalHistoryQuestion) => question?.questionSubType !== QuestionSubTypeEnum.SKIN_TYPE
        );

        const medicalHistoryQuestions = hasFitzpatrickQuestion
            ? medicalHistoryQuestionsWithoutFitzpatrick
            : allMedicalHistoryQuestions;

        const biopsyQuestions = isNonSkinCancerFlow ? [] : this.getBiopsyQuestions(questionAnswers);
        const { pendingRequest } = this.props;
        const { errors, isBack, medicalHistoryAnswers, isEditMode } = this.state;

        const nextButtons = [
            ...(isBack
                ? []
                : [
                      {
                          action: this.saveCase,
                          isLoading: pendingRequest,
                          isDisabled: pendingRequest,
                          text: "Save and Close",
                          type: "submit" as const,
                      },
                  ]),
            {
                action: this.submit,
                isLoading: pendingRequest,
                isDisabled: pendingRequest,
                text: isBack ? "Next >" : "Save and Continue",
                type: "submit" as const,
            },
        ];

        const questionSets = [
            { questions: medicalHistoryQuestions },
            ...(biopsyQuestions.length
                ? [
                      {
                          label: "Biopsy Questions",
                          questions: biopsyQuestions,
                      },
                  ]
                : []),
        ];

        return (
            <Container className="ui segment wizzard-container">
                {showIntegrationPatientCreatedModal && (
                    <div className="integration-patient-created-modal__wrapper">
                        The patient record has been created
                    </div>
                )}
                <PatientBanner caseId={caseId} patient={patient} />
                <AssessmentFlowHeader
                    title="Medical History"
                    isEditMode={isEditMode}
                    disabled={!medicalHistory}
                    edit={this.editMedicalHistory}
                    reset={this.resetEdit}
                />
                <Divider />
                <Questionary
                    questionSets={questionSets}
                    errors={errors}
                    isBack={isBack && !isEditMode}
                    updateAnswers={this.updateAnswers}
                    answers={medicalHistoryAnswers}
                    updateErrors={this.updateErrors}
                    backButton={{
                        isDisabled: isEditMode,
                        link: backButtonLink,
                        text: "< Back",
                    }}
                    nextButtons={nextButtons}
                />
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.editMedicalHistory}
                />
            </Container>
        );
    }
}

export default MedicalHistoryQuestions;
