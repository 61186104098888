import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Information from "components/MFA/Information";
import PhoneNumberRegistration from "components/MFA/PhoneNumberRegistration";
import VerifyOTP from "components/MFA/VerifyOTP";
import TrustedDevice from "components/MFA/TrustedDevice";
import StepIndicator from "components/MFA/StepIndicator";
import UntrustedDeviceGuidance from "components/MFA/UntrustedDeviceGuidance";
import InterstitialPage from "components/templates/InterstitialPage";

import { MFAVerificationChannel } from "model/mfa";

import * as actions from "redux/actions";
import getMFASetupState from "redux/selectors/mfa";

const NUMBER_OF_STEPS = 3;

const Setup: FC = () => {
    const dispatch = useDispatch();
    const mfaSetupState = useSelector(getMFASetupState);
    const { gracePeriodCountdownInDays, step } = mfaSetupState;
    const emailVerificationTitle = "Let's start by verifying your email";
    const smsVerificationTitle = "Let’s finish by verifying your mobile number";

    const allowSkip = useMemo(() => gracePeriodCountdownInDays > 0, []);

    const handleNext = (): void => {
        dispatch(actions.mfaActions.incrementStep());
    };

    const handleBack = (): void => {
        dispatch(actions.mfaActions.decrementStep());
    };

    return (
        <InterstitialPage>
            <StepIndicator
                step={step}
                stepCount={NUMBER_OF_STEPS}
                allowBack={step === 3}
                allowSkip={allowSkip}
                handleBack={handleBack}
            />
            {step === 0 ? <Information handleNext={handleNext} /> : null}
            {step === 1 ? (
                <VerifyOTP
                    channel={MFAVerificationChannel.EMAIL}
                    title={emailVerificationTitle}
                    handleVerification={handleNext}
                />
            ) : null}
            {step === 2 ? <PhoneNumberRegistration /> : null}
            {step === 3 ? (
                <VerifyOTP
                    channel={MFAVerificationChannel.SMS}
                    title={smsVerificationTitle}
                    handleVerification={handleNext}
                />
            ) : null}
            {step === 4 ? <TrustedDevice handleNext={handleNext} /> : null}
            {step === 5 ? <UntrustedDeviceGuidance handleBack={handleBack} /> : null}
        </InterstitialPage>
    );
};

export default Setup;
