import clsx from "clsx";

import { calculateTimeDifference, formatDateForForm } from "helpers/datetime";

import { IPatient } from "model/assessment";
import { IPatientData, ISkinToneAnswer } from "model/case";
import { DefinedPersonalDataType, IDefinedPersonalData } from "model/organisation";
import { IPatientEdit } from "model/patientEdit";
import { IMobileNumberValidation } from "model/remoteModel";

export enum MobileNumberValidationErrors {
    INVALID_FORMAT = "Invalid mobile number format",
    NUMBER_CONTAINS_LETTERS = "Your mobile number must contain only numbers",
    PHONE_NUMBER_DOES_NOT_EXIST = "Looks like this phone number does not exist. Provide a valid, non-virtual phone number to continue.",
    INVALID_LENGTH = "Your mobile number must be at least 10 characters and less than 15 characters",
    TOO_SHORT = "Your mobile number must be at least 10 characters",
    TOO_LONG = "Your mobile number must be less than 15 characters",
    INVALID_COUNTRY_CODE = "Your country code is incorrect, please select a valid country code",
    STARTS_WITH_ZERO = "Your mobile number must not begin with zero",
}

export enum MobileNumberBackendValidationErrorMap {
    INVALID_LENGTH = MobileNumberValidationErrors.INVALID_LENGTH,
    TOO_SHORT = MobileNumberValidationErrors.TOO_SHORT,
    TOO_LONG = MobileNumberValidationErrors.TOO_LONG,
    INVALID_BUT_POSSIBLE = MobileNumberValidationErrors.TOO_SHORT,
    INVALID_COUNTRY_CODE = MobileNumberValidationErrors.INVALID_COUNTRY_CODE,
    NOT_A_NUMBER = MobileNumberValidationErrors.PHONE_NUMBER_DOES_NOT_EXIST,
}

export interface IValidateMobileNumber {
    mobileNumber: string;
    validateForMFA?: boolean;
    maxLength?: number;
}

export const getInvalidLengthError = (length: number): string => {
    if (!length || Number.isNaN(length)) {
        return "Please check the length of your mobile number";
    }
    return `Your mobile number must be ${length} characters`;
};

const PHONE_REGEX = /^\+?[\d ]+$/;
export enum MobileNumberMaxLength {
    INTERNATIONAL_NUMBER_MAX_LENGTH = 16,
    UK_NUMBER_MAX_LENGTH = 11,
    MFA_VERIFICATION_UK_NUMBER_MAX_LENGTH = 10,
    MFA_VERIFICATION_INTERNATIONAL_NUMBER_MAX_LENGTH = 15,
}
const PLUS = "+";
const ZERO = "0";
const DOUBLE_ZERO = "00";
const UK_PHONE_CODE = "+44";

export const validationConfig = {
    standard: {
        allowCountryCodeOrZeroStart: true,
        INTERNATIONAL_NUMBER_MAX_LENGTH: MobileNumberMaxLength.INTERNATIONAL_NUMBER_MAX_LENGTH,
        UK_NUMBER_MAX_LENGTH: MobileNumberMaxLength.UK_NUMBER_MAX_LENGTH,
    },
    mfa: {
        allowCountryCodeOrZeroStart: false,
        INTERNATIONAL_NUMBER_MAX_LENGTH: MobileNumberMaxLength.MFA_VERIFICATION_INTERNATIONAL_NUMBER_MAX_LENGTH,
        UK_NUMBER_MAX_LENGTH: MobileNumberMaxLength.MFA_VERIFICATION_UK_NUMBER_MAX_LENGTH,
    },
};

function getDateWithAge(dateOfBirth: string, dateOfDeath?: string): string {
    const years = calculateTimeDifference(dateOfBirth, dateOfDeath, "years");
    return `${dateOfBirth} (${years} years)`;
}

interface IGetPatientData {
    patientDataItem: IPatientData | null;
    dateFormatter?: (time: string) => string;
    withAge?: boolean;
    dateOfDeath?: string;
}

export function getPatientData({
    patientDataItem,
    dateFormatter,
    withAge,
    dateOfDeath,
}: IGetPatientData): string | number | boolean {
    switch (patientDataItem?.type) {
        case DefinedPersonalDataType.TEXT:
        case DefinedPersonalDataType.OPTIONS: {
            return patientDataItem.textValue;
        }
        case DefinedPersonalDataType.DATE: {
            if (dateFormatter) {
                const { dateValue } = patientDataItem;
                const formattedDate = dateFormatter(dateValue);
                if (withAge) {
                    return getDateWithAge(formattedDate, dateOfDeath);
                }
                return formattedDate;
            }
            return formatDateForForm(patientDataItem.dateValue);
        }
        case DefinedPersonalDataType.NUMBER: {
            return patientDataItem.numberValue;
        }
        case DefinedPersonalDataType.BOOLEAN: {
            return patientDataItem.booleanValue;
        }
        default: {
            return undefined;
        }
    }
}

export function getPatientField(patientData: IPatientData[], fieldName: string): IPatientData | null | undefined {
    if (patientData) {
        return patientData.find((data) => data.name === fieldName);
    }
    return null;
}

export function getPatientFields(patient: IPatient, fieldNames: string[]) {
    const { patientData } = { ...patient };
    if (patientData) {
        return fieldNames.map((fieldName: string) =>
            getPatientData({ patientDataItem: getPatientField(patientData, fieldName) })?.toString()
        );
    }
    return fieldNames.map(() => "");
}

export function getStringWithoutBackspaces(string: string): string {
    return string.replace(/\s/g, "");
}

export function validateMobileNumber({
    mobileNumber,
    validateForMFA = false,
}: IValidateMobileNumber): IMobileNumberValidation {
    if (mobileNumber === "") {
        return { isError: true, message: MobileNumberValidationErrors.INVALID_FORMAT };
    }

    const { INTERNATIONAL_NUMBER_MAX_LENGTH: maxLength, allowCountryCodeOrZeroStart } = validateForMFA
        ? validationConfig.mfa
        : validationConfig.standard;
    const onlyNumbers = PHONE_REGEX.test(mobileNumber);
    const numberWithoutBackspaces = getStringWithoutBackspaces(mobileNumber);
    const isLengthValid = numberWithoutBackspaces.length <= maxLength;
    const startsWithPlus = mobileNumber.startsWith(PLUS);
    const startsWithZero = mobileNumber.startsWith(ZERO);
    const startsWithDoubleZero = mobileNumber.startsWith(DOUBLE_ZERO);
    const startsWithCountryCodeOrZero = startsWithPlus || startsWithZero || startsWithDoubleZero;
    const startsWithAllowedSymbols = allowCountryCodeOrZeroStart
        ? startsWithCountryCodeOrZero
        : !startsWithCountryCodeOrZero;

    if (!allowCountryCodeOrZeroStart && startsWithCountryCodeOrZero) {
        return { isError: true, message: MobileNumberValidationErrors.STARTS_WITH_ZERO };
    }

    if (onlyNumbers && isLengthValid && startsWithAllowedSymbols) {
        return { isError: false };
    }

    if (!onlyNumbers) {
        return { isError: true, message: MobileNumberValidationErrors.NUMBER_CONTAINS_LETTERS };
    }

    if (!isLengthValid) {
        return { isError: true, message: getInvalidLengthError(maxLength) };
    }

    return { isError: true, message: MobileNumberValidationErrors.INVALID_FORMAT };
}

export function formatMobileNumber(mobileNumber: string, countryCode = UK_PHONE_CODE) {
    const numberWithoutBackspaces = getStringWithoutBackspaces(mobileNumber);
    const isInternationalFormat = numberWithoutBackspaces.startsWith(PLUS);

    if (isInternationalFormat) {
        return numberWithoutBackspaces;
    }
    if (numberWithoutBackspaces.startsWith(DOUBLE_ZERO)) {
        return mobileNumber.replace(DOUBLE_ZERO, PLUS);
    }
    if (numberWithoutBackspaces.startsWith(ZERO)) {
        return numberWithoutBackspaces.replace(ZERO, countryCode);
    }
    if (!numberWithoutBackspaces.startsWith(ZERO)) {
        return `${countryCode}${numberWithoutBackspaces}`;
    }
    return numberWithoutBackspaces;
}

function getPatientDataValue(propertyName: string, patient: IPatient): string | number | boolean {
    const patientDataItem: IPatientData = patient?.patientData.find(
        ({ name, removed }) => name === propertyName && !removed
    );

    if (patientDataItem) {
        return getPatientData({ patientDataItem });
    }
    return "";
}

export function getInitialPatientData(personalDataFields: IDefinedPersonalData[], patient: IPatient): IPatientEdit {
    const initialPatientData = {};
    personalDataFields.forEach((field: IDefinedPersonalData) => {
        const { propertyName } = field;

        initialPatientData[propertyName] = getPatientDataValue(propertyName, patient);
    });
    return initialPatientData;
}

export function getHospitalNumberClassName(isDeceasedPatient: boolean, isMergedRecord: boolean): string {
    return clsx(
        (isDeceasedPatient || isMergedRecord) &&
            `hospital-number-label${isMergedRecord && isDeceasedPatient ? "s" : ""}`
    );
}

export function getSkinToneClassificationByAssigneeUuid(patient: IPatient, currentUserUuid: string): ISkinToneAnswer {
    return patient?.skinToneClassification?.find(
        (classification: ISkinToneAnswer) => classification.createdBy === currentUserUuid
    );
}
