import { Component, MouseEvent as ReactMouseEvent, ReactNode } from "react";
import { withRouter, Link, NavLink, RouteComponentProps } from "react-router-dom";
import { Dropdown, Header as ReactHeader, Icon, Image, Menu } from "semantic-ui-react";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import ConditionalRender from "components/templates/ConditionalRender";
import OrganisationHeaderItem from "components/templates/header/OrganisationHeaderItem";
import OrganisationSelectHeaderItem from "components/templates/header/OrganisationSelectHeaderItem";
import SingleHeaderItem from "components/templates/header/SingleHeaderItem";

import { getLinkToPatientAssessment } from "helpers/caseList";

import { UserRole } from "model/userRole";
import { IAssessment } from "model/assessment";
import { IOrganisationData } from "model/organisation";

import { history } from "App";

import {
    REMOTE_MODEL_CASES_START,
    REMOTE_MODEL_CONSENT,
    REMOTE_MODEL_REGISTRATION,
} from "navigation/remoteModelRoutes";
import {
    ADMINISTRATION,
    GUIDANCE,
    HOME,
    MFA_AUTHENTICATION,
    MFA_MOBILE_NUMBER_VERIFICATION,
    MFA_SETUP,
    MFA_TRUSTED_DEVICE_REINITIALISATION,
    UPDATE_PASSWORD,
    USER_SETTINGS,
} from "navigation/routes";

import * as authorizationService from "services/authorizationService";
import userService from "services/userService";

import "scss/Button.scss";
import "scss/Header.scss";

interface IHeader extends RouteComponentProps {
    data: {
        user: any;
        organisation: any;
        pendingRequest: boolean;
    };
    signedIn: boolean;
    showOrganisationSelection: boolean;
    logout: () => void;
    assessment: IAssessment;
}

interface IHeaderState {
    isSigned: boolean;
    anchorEl: HTMLElement | null;
}

class Header extends Component<IHeader, IHeaderState> {
    constructor(props: IHeader) {
        super(props);
        this.state = {
            isSigned: authorizationService.isSignedIn(),
            anchorEl: null,
        };

        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
    }

    public componentDidUpdate(prevProps: IHeader, prevState: IHeaderState) {
        const { signedIn } = this.props;

        if (prevState.isSigned !== signedIn) {
            this.setIsSigned();
        }
    }

    private static onCasesClick(
        e: ReactMouseEvent<HTMLDivElement, MouseEvent> | ReactMouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
        if (userService.checkUserHasRole([UserRole.SUPERADMIN]) && window.location.pathname === HOME) {
            e.preventDefault();
            window.location.reload();
        }
        if (userService.checkUserHasRole([UserRole.PATIENT])) {
            history.push(HOME);
        }
    }

    private handleItemClick(e: ReactMouseEvent<HTMLElement>) {
        this.setState({ anchorEl: e.currentTarget });
    }

    private setIsSigned() {
        this.setState({ isSigned: authorizationService.isSignedIn() });
    }

    private handleClickAway = () => {
        this.setState({ anchorEl: null });
    };

    private handleCloseContextMenu = () => {
        this.handleClickAway();
    };

    private getAdminMenuItem() {
        const { isSigned } = this.state;
        return (
            isSigned && (
                <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
                    <NavLink to={ADMINISTRATION} activeClassName="active-header-tab">
                        <Menu.Item className="admin-tab-header">ADMIN TOOLS</Menu.Item>
                    </NavLink>
                </ConditionalRender>
            )
        );
    }

    private getCasesMenuItem() {
        const { showOrganisationSelection } = this.props;
        const { isSigned } = this.state;

        return (
            isSigned &&
            !showOrganisationSelection && (
                <NavLink onClick={Header.onCasesClick} to="/home" activeClassName="active-header-tab">
                    <Menu.Item className="cases-tab-header">CASES</Menu.Item>
                </NavLink>
            )
        );
    }

    private getUserMenuItem(isPatient?: boolean) {
        const {
            data: { user },
        } = this.props;
        const { name, surname } = user;
        const { isSigned, anchorEl } = this.state;
        const icon = isPatient ? "user outline" : "stethoscope";
        const fullName = `${name} ${surname}`;

        return (
            isSigned &&
            user && (
                <>
                    <div className="user-menu-container">
                        <Icon className="user-icon" name={icon} size="large" />
                        <Button
                            tabIndex={0}
                            className="user-menu-icon-button"
                            onClick={this.handleItemClick}
                            disableRipple
                        >
                            <div className="user-menu-details-container">
                                <div>
                                    {isPatient ? (
                                        <span>{fullName}</span>
                                    ) : (
                                        <div className="user-menu-user-info">
                                            <ReactHeader as="h5">User</ReactHeader>
                                            <span>{fullName}</span>
                                        </div>
                                    )}
                                </div>
                                <Icon name="angle down" size="big" />
                            </div>
                        </Button>
                    </div>
                    <MuiMenu
                        className="user-menu-items"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleCloseContextMenu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        getContentAnchorEl={null}
                    >
                        <MenuItem
                            tabIndex={0}
                            onClick={() => {
                                this.goToUserSettings();
                                this.handleCloseContextMenu();
                            }}
                        >
                            Settings
                        </MenuItem>
                        <MenuItem tabIndex={0} onClick={this.logout}>
                            Logout
                        </MenuItem>
                    </MuiMenu>
                </>
            )
        );
    }

    private getOrganisationMenuItem() {
        const { data, showOrganisationSelection } = this.props;
        const { isSigned } = this.state;

        const { organisation, user } = data;
        const renderMenu = isSigned && organisation && !showOrganisationSelection;
        const renderOrganisationSelection =
            user.additionalOrganisationData?.length > 0 || (user.linkedUser && !user.linkedUser.removed);

        const organisations: IOrganisationData[] = [
            { organisationName: user.organisationName, organisationUuid: user.organisationUuid },
        ];

        if (user?.additionalOrganisationData?.length > 0) {
            user.additionalOrganisationData.forEach((org: IOrganisationData) => organisations.push(org));
        }

        if (user.linkedUser && !user.linkedUser.removed) {
            const {
                linkedUser: { linkedUserOrganisationName, linkedUserOrganisationUuid },
            } = user;

            const currentOrgUuids = organisations.map((org) => org.organisationUuid);

            if (!currentOrgUuids.includes(linkedUserOrganisationUuid)) {
                organisations.push({
                    organisationName: linkedUserOrganisationName,
                    organisationUuid: linkedUserOrganisationUuid,
                });
            }
        }

        const organisationMenu: ReactNode = !renderOrganisationSelection ? (
            <OrganisationHeaderItem organisationName={organisation.name} />
        ) : (
            <OrganisationSelectHeaderItem organisations={organisations} existingOrganisation={organisation} />
        );

        return renderMenu ? organisationMenu : null;
    }

    private getCaseNumberMenuItem() {
        const { assessment } = this.props;

        const { case: currentCase } = assessment;
        const { caseId = 0 } = currentCase || {};

        if (caseId) {
            return (
                <SingleHeaderItem
                    iconName="edit outline"
                    text={`Case\u00A0#${caseId}`}
                    onClick={this.handleCurrentCaseClick}
                    allowedRoles={[UserRole.PATIENT]}
                />
            );
        }
        return null;
    }

    private getAboutPageMenuItem() {
        const { data, showOrganisationSelection } = this.props;
        const { isSigned } = this.state;

        const { organisation } = data;

        return (
            isSigned &&
            organisation &&
            !showOrganisationSelection && (
                <SingleHeaderItem
                    iconName="question circle"
                    text="Guidance"
                    onClick={this.goToAbout}
                    allowedRoles={[UserRole.CLINICIAN, UserRole.DERMATOLOGIST, UserRole.ADMIN, UserRole.PATIENT]}
                />
            )
        );
    }

    private getMyCasesMenuItem() {
        const { isSigned } = this.state;

        return (
            isSigned && (
                <SingleHeaderItem
                    iconName="clipboard outline"
                    text="My&nbsp;cases"
                    onClick={Header.onCasesClick}
                    allowedRoles={[UserRole.PATIENT]}
                />
            )
        );
    }

    private logout = () => {
        const { logout } = this.props;

        logout();
    };

    private goToUserSettings = () => {
        history.push(USER_SETTINGS);
    };

    private goToAbout = () => {
        history.push(GUIDANCE);
    };

    private getMobileDropdown = (isPatient: boolean) => {
        const { data, assessment } = this.props;
        const { user } = data;
        const { case: currentCase } = assessment;
        const { caseId = 0 } = currentCase || {};

        return (
            <Menu.Item>
                <Dropdown item={false} direction="left" icon="bars">
                    <Dropdown.Menu>
                        {user ? <Dropdown.Item>{`${user.name} ${user.surname}`}</Dropdown.Item> : null}
                        <Dropdown.Divider />
                        {isPatient && (
                            <>
                                <Dropdown.Item onClick={Header.onCasesClick}>My&nbsp;cases</Dropdown.Item>
                                <Dropdown.Divider />
                            </>
                        )}
                        {caseId ? (
                            <>
                                <Dropdown.Item onClick={this.handleCurrentCaseClick}>
                                    Case Number {caseId}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                            </>
                        ) : null}
                        <Dropdown.Item onClick={this.goToUserSettings}>Settings</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
        );
    };

    private handleCurrentCaseClick = () => {
        const { assessment } = this.props;
        const nextUrl = getLinkToPatientAssessment(assessment);
        history.push(nextUrl);
    };

    public render() {
        const { signedIn, location } = this.props;
        const { pathname } = location;
        const pagesWithOnlyLogo: string[] = [
            REMOTE_MODEL_CONSENT,
            REMOTE_MODEL_REGISTRATION,
            REMOTE_MODEL_CASES_START,
            UPDATE_PASSWORD,
            MFA_SETUP,
            MFA_AUTHENTICATION,
            MFA_MOBILE_NUMBER_VERIFICATION,
            MFA_TRUSTED_DEVICE_REINITIALISATION,
        ];

        const onlyLogo = pagesWithOnlyLogo.includes(pathname);

        const isPatient = signedIn && userService.checkUserHasRole([UserRole.PATIENT]);

        return (
            <header className="main-header">
                <Menu stackable>
                    <Menu.Item className="header-logo-item">
                        <Link to={HOME}>
                            <Image alt="Skin Analytics logo" src="/images/skinLogo.svg" className="logo" />
                        </Link>
                    </Menu.Item>
                    {onlyLogo || !signedIn ? null : (
                        <>
                            {!isPatient && this.getCasesMenuItem()}
                            {this.getAdminMenuItem()}
                            <Menu.Menu position="right" className="header-right-section">
                                {this.getAboutPageMenuItem()}
                                {this.getMyCasesMenuItem()}
                                {isPatient ? this.getCaseNumberMenuItem() : this.getOrganisationMenuItem()}
                                {this.getUserMenuItem(isPatient)}
                            </Menu.Menu>
                            <div className="header-right-section-mobile">
                                {this.getAboutPageMenuItem()}
                                {this.getMobileDropdown(isPatient)}
                            </div>
                        </>
                    )}
                </Menu>
            </header>
        );
    }
}

export default withRouter(Header);
