import { FC } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { handleKeyDown } from "helpers/input";
import { MFAButtons } from "model/mfa";

import * as actions from "redux/actions";

import { homeScreenInitialisation } from "services/authorizationService";
import userService from "services/userService";

import "scss/MFASetup.scss";

interface ITrustedDevice {
    handleNext: () => void;
}

const TrustedDevice: FC<ITrustedDevice> = ({ handleNext }) => {
    const dispatch = useDispatch();
    const trustDevice = async (): Promise<void> => {
        await userService.saveTrustedDevice();
        dispatch(actions.mfaActions.mfaCompleteSetup());
        homeScreenInitialisation(dispatch);
    };

    const doNotTrustDevice = (): void => {
        handleNext();
    };

    return (
        <>
            <Box>
                <Box display="flex" alignContent="center">
                    <img className="mfa-setup-icon" alt="MFA setup icon" src="/images/MFASetupComplete.png" />
                </Box>
                <Box>
                    <h2>Multi-factor authentication set up completed</h2>
                    <Box>
                        <p>You can choose to trust this device for a quicker and smoother login process.</p>
                        <p>
                            By trusting your device, you can skip additional authentication for the next 90 days. After
                            that, you will need to re-authenticate to keep your account secure.
                        </p>
                    </Box>

                    <Box display="flex" flexDirection="row" paddingTop={2}>
                        <Box marginRight={0.5} width="50%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="secondary"
                                onClick={doNotTrustDevice}
                                onKeyDown={(e) => handleKeyDown(e, doNotTrustDevice)}
                            >
                                {MFAButtons.DO_NOT_TRUST_DEVICE}
                            </Button>
                        </Box>
                        <Box marginLeft={0.5} width="50%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="primary"
                                onClick={trustDevice}
                                onKeyDown={(e) => handleKeyDown(e, trustDevice)}
                            >
                                {MFAButtons.TRUST_DEVICE}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TrustedDevice;
