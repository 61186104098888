import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { IStructuredTemplateField, ITemplate } from "model/organisation";

const parseAdminNextStepsTemplate = (data: ITemplate): IAdminNextStepQuestion[] | undefined => {
    if (!data) {
        return undefined;
    }
    const questions: IAdminNextStepQuestion[] = [];

    const { uuid, organisationUuid, fields } = data;

    fields.forEach((field) => {
        const {
            uuid: fieldUuid,
            question: questionText,
            questionType,
            childStructuredFields,
            parentFieldUuid,
            required,
            responseOptions,
            removed,
        } = field as IStructuredTemplateField;

        if (parentFieldUuid || removed) {
            return;
        }

        const question: IAdminNextStepQuestion = {
            templateUuid: uuid,
            organisationUuid,
            questionUuid: fieldUuid,
            question: questionText,
            questionType,
            parentQuestionUuid: undefined,
            required: required || false,
            options: responseOptions?.length ? responseOptions.map((value: any) => value.option) : responseOptions,
        };

        questions.push(question);

        if (childStructuredFields?.length) {
            childStructuredFields
                .filter((childField) => !childField.removed)
                .forEach((childField) => {
                    const childQuestion: IAdminNextStepQuestion = {
                        templateUuid: uuid,
                        organisationUuid,
                        parentQuestionUuid: fieldUuid,
                        questionUuid: childField.uuid,
                        question: childField.question,
                        questionType: childField.questionType,
                        required: childField.required,
                        options: childField.responseOptions?.length
                            ? childField.responseOptions.map((value: any) => value.option)
                            : childField.responseOptions,
                    };

                    questions.push(childQuestion);
                });
        }
    });

    return questions;
};

const buildQuestionsHierarchy = (
    questions: IAdminNextStepQuestion[]
): Map<IAdminNextStepQuestion, IAdminNextStepQuestion[]> => {
    const adminNextStepsMap = new Map<IAdminNextStepQuestion, IAdminNextStepQuestion[]>();
    if (questions?.length) {
        questions
            .filter((question) => !question.parentQuestionUuid)
            .forEach((parent) => {
                const children = questions
                    .filter((question) => question.parentQuestionUuid === parent.questionUuid)
                    .sort(
                        (s1: IAdminNextStepQuestion, s2: IAdminNextStepQuestion) =>
                            new Date(s2.creationDate).getTime() - new Date(s1.creationDate).getTime()
                    );
                adminNextStepsMap.set(parent, children);
            });
    }
    return adminNextStepsMap;
};

export { parseAdminNextStepsTemplate, buildQuestionsHierarchy };
