import { FC, ClipboardEvent, memo, useMemo, useCallback, useRef, KeyboardEvent } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import "scss/MFASetup.scss";

const generateFieldNames = (count: number): string[] => Array.from({ length: count }, (_, i) => `input-${i}`);

export interface IMultiInputTextField {
    onChange: (value: string) => void;
    singleInputRegex: RegExp;
    inputsNumber: number;
    useFormInstance: UseFormReturn;
}

const MultiInputTextField: FC<IMultiInputTextField> = memo(
    ({ onChange, singleInputRegex, inputsNumber, useFormInstance }) => {
        const inputRef = useRef(null);

        const fieldNames = useMemo(() => generateFieldNames(inputsNumber), []);
        const {
            control,
            formState: { errors },
            setValue,
            trigger,
            getValues,
        } = useFormInstance;

        const validateInput = useCallback((input: string): void => {
            trigger();
            if (input.length !== inputsNumber) {
                throw new Error("Invalid input length");
            }
            if (input.split("").some((value) => Number.isNaN(Number(value)))) {
                throw new Error("Input contains non-numeric characters");
            }
        }, []);

        const handlePaste = useCallback((event: ClipboardEvent<HTMLElement>): void => {
            event.preventDefault();
            const pastedValues = event.clipboardData.getData("text/plain").trim();

            try {
                validateInput(pastedValues);
                for (let i = 0; i < pastedValues?.length; i += 1) {
                    setValue(fieldNames[i], pastedValues[i]);
                    trigger(fieldNames[i]);
                }
                const concatenatedValue = Object.values(getValues()).join("");
                onChange(concatenatedValue);
            } catch (error) {
                console.error(error);
            }
        }, []);

        const getMap = useCallback((): Map<string, HTMLInputElement> => {
            if (!inputRef.current) {
                inputRef.current = new Map();
            }
            return inputRef.current;
        }, []);

        const setRef = useCallback((node: unknown, fieldName: string): void => {
            const element = node as HTMLInputElement;
            const map = getMap();
            if (node) {
                map.set(fieldName, element);
            } else {
                map.delete(fieldName);
            }
        }, []);

        const handleDigitChange = useCallback((event, field, index): void => {
            const {
                target: { value },
            } = event;
            const map = getMap();
            const currentInput = map.get(`input-${index}`);
            const nextInput = map.get(`input-${index + 1}`);

            if (singleInputRegex.test(value)) {
                currentInput.select();
                field.onChange(value);
                currentInput.focus();
                if (nextInput) {
                    nextInput.focus();
                }
            } else if (value === "") {
                field.onChange(value);
            }

            const concatenatedValue = Object.values(getValues()).join("");
            onChange(concatenatedValue);
        }, []);

        const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, index: number): void => {
            const map = getMap();
            const prevInput = map.get(`input-${index - 1}`);
            const currentInput = map.get(`input-${index}`);
            currentInput.select();
            const target = event.target as HTMLInputElement;
            if (event.key === "Backspace" && target?.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            }
        };

        return (
            <Box className="otp-input-wrapper">
                {fieldNames.map((fieldName, index) => {
                    const accessibleLabel = `Please enter digit ${index + 1} of your one time passcode`;
                    return (
                        <div key={fieldName}>
                            <label htmlFor={fieldName} style={{ display: "none" }}>
                                Digit {index + 1} of your one-time password
                            </label>
                            <Controller
                                name={fieldName}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    maxLength: 1,
                                    min: 0,
                                    max: 9,
                                    pattern: singleInputRegex,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        autoFocus={index === 0}
                                        className="otp-input"
                                        data-testid={fieldName}
                                        error={!!errors[fieldName]}
                                        id={fieldName}
                                        name={fieldName}
                                        inputProps={{
                                            "aria-atomic": "true",
                                            "aria-label": accessibleLabel,
                                            "aria-invalid": errors[fieldName] ? "true" : "false",
                                            "maxLength": 1,
                                            "inputMode": "numeric",
                                            "pattern": singleInputRegex.source,
                                            "min": 0,
                                            "max": 9,
                                            "ref": (node) => setRef(node, fieldName),
                                        }}
                                        onChange={(e) => handleDigitChange(e, field, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                    );
                })}
            </Box>
        );
    }
);

export default MultiInputTextField;
