import { CustomMessageDetails } from "components/templates/CustomMessage";

export const OTP_LENGTH = 6;
export const OTP_SINGLE_INPUT_REGEXP = new RegExp(/^\d$/);
export const OTP_VALIDATION_REGEXP = new RegExp(`^\\d{${OTP_LENGTH}}$`);

export enum MFASetupStepTitle {
    EMAIL_VERIFICATION = "Let's start by verifying your email",
}

export enum MFAButtons {
    START_SETUP = "Start set up",
    CONTINUE = "Continue",
    TRUST_DEVICE = "Trust this device",
    DO_NOT_TRUST_DEVICE = "Do not trust this device",
    AUTHENTICATE_NOW = "Authenticate now",
    BACK = "< Back",
    SKIP = "Skip",
    MODAL_OK = "Ok",
}

export enum MFAKeys {
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
    SMS_VERIFICATION = "SMS_VERIFICATION",
}

export enum MFAVerificationChannel {
    EMAIL = "email",
    SMS = "sms",
}

export enum MFAUserFriendlyChannel {
    email = "email",
    sms = "SMS",
}

export enum MFASetupStatus {
    VERIFIED = "VERIFIED",
    NOT_VERIFIED = "NOT_VERIFIED",
    RE_VERIFICATION_REQUIRED = "RE_VERIFICATION_REQUIRED",
    PENDING = "PENDING",
}

export enum VerificationStatus {
    VERIFIED = "VERIFIED",
    NOT_VERIFIED = "NOT_VERIFIED",
    RE_VERIFICATION_REQUIRED = "RE_VERIFICATION_REQUIRED",
    UNINITIATED = "uninitiated",
    PENDING = "pending",
    APPROVED = "approved",
    CANCELLED = "canceled", // US English spelling as per Twilio response
}

export enum MFAInitialScreen {
    NO_MFA = "NO_MFA",
    SETUP = "SETUP",
    AUTHENTICATION = "AUTHENTICATION",
    VERIFY_MOBILE_NUMBER = "VERIFY_MOBILE_NUMBER",
    TRUST_DEVICE_REINITIALISATION = "TRUST_DEVICE_REINITIALISATION",
}

export enum MFASetupStep {
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
    SMS_VERIFICATION = "SMS_VERIFICATION",
}

export enum TrustedDeviceStatus {
    TRUSTED = "TRUSTED",
    NOT_TRUSTED = "NOT_TRUSTED",
    ABOUT_TO_EXPIRE = "ABOUT_TO_EXPIRE",
}

export interface ISMSChanelDestination extends IMobileNumberAndCountryCode {
    concatenatedMobileNumber?: string;
}

export interface IMobileNumberAndCountryCode {
    countryCode: string;
    mobileNumber: string;
}

export interface IMFAVerification {
    status: MFASetupStatus;
    verificationDate: Date;
    destination: string;
}

export interface IMFASetupState {
    step: number;
    initialScreen: MFAInitialScreen;
    hasSeenMFAScreen: boolean;
    trustedDeviceStatus: TrustedDeviceStatus;
    daysUntilTrustedDeviceExpiry: number | null;
    gracePeriodCountdownInDays: number;
    email: IMFAVerification;
    sms: IMFAVerification;
    isEnabledForAnyOrganisation: boolean;
}

export interface ITrustedDevice {
    uuid: string;
    userUuid: string;
    userAgent: string;
}

export const trustedDeviceRemovalError: CustomMessageDetails = {
    message: "Trusted device removal failed",
};

export const otpValidationError: CustomMessageDetails = {
    message: "The code you entered is not valid",
    cta: "Double check and make sure to enter it exactly as it appears. If the problem persists, you can try to resend a new one.",
};

export const otpGenericError: CustomMessageDetails = {
    message: "Something went wrong",
    showDefaultHelp: true,
};
