import React, { FC, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dimmer } from "semantic-ui-react";

import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import FoldableContainer from "components/RemoteModel/ReportAvailable/FoldableContainer";
import RequestCallback from "components/RemoteModel/ReportAvailable/RequestCallback";
import AssessmentReport from "components/RemoteModel/ReportAvailable/AssessmentReport";
import WhatHappensNext from "components/RemoteModel/ReportAvailable/WhatHappensNext";
import FindOutMoreInformation from "components/RemoteModel/ReportAvailable/FindOutMoreInformation";
import Lesions from "components/RemoteModel/ReportAvailable/Lesions";
import QuestionsSection from "components/CaseDescription/Questions/QuestionsSection";
import RemoteModelContainer from "components/templates/RemoteModelContainer";
import LoadingSpinner from "components/templates/LoadingSpinner";

import { ReportAvailableContext } from "contextProviders/ReportAvailableProvider";
import { ReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";

import { hasCallbackOutcomeCompleted } from "helpers/cases";

import { CaseStatus } from "model/caseStatus";
import { AssessmentTypes, RemoteModelManagementOutcomes, RemoteModelStatuses } from "model/assessment";
import { ICase } from "model/case";
import { IMedicalHistoryQuestion } from "model/organisation";

import { getAssessment } from "redux/selectors/assessment";
import { getOrganisation } from "redux/selectors/data";
import { assessmentActions } from "redux/actions";

import * as caseService from "services/caseService";

const ReportAvailable: FC = () => {
    const {
        state: { caseStatus, isAwaitingCallback },
        dispatch: dispatchReportAvailable,
    } = useContext(ReportAvailableContext);

    const [medicalQuestionDefinition, setMedicalQuestionDefinition] = useState<IMedicalHistoryQuestion[]>([]);
    const assessment = useSelector(getAssessment);
    const organisation = useSelector(getOrganisation);
    const dispatch = useDispatch();

    const { patient, case: currentCase } = assessment;
    const { uuid: currentCaseUuid, lesions, reviews, callbackOutcome } = { ...currentCase };
    const { medicalHistoryQuestions, uuid: organisationUuid } = organisation;

    useEffect(() => {
        async function fetchData() {
            try {
                const currentCaseData: ICase = await caseService.getCaseAsync(currentCaseUuid);
                dispatch(assessmentActions.setCase(currentCaseData));

                const isCaseClosed = currentCaseData.caseStatus === CaseStatus.CASE_CLOSED;
                const caseStatusPanel = isCaseClosed
                    ? RemoteModelStatuses.CASE_CLOSED
                    : RemoteModelStatuses.REPORT_AVAILABLE;
                const isCurrentCaseAwaitingCallback = Boolean(currentCaseData.isCallbackNeeded);

                dispatchReportAvailable({
                    type: ReportAvailableActions.SET_CASE_STATUS,
                    payload: {
                        caseStatus: caseStatusPanel,
                        isSaveReportButtonAvailable: isCaseClosed,
                        isAwaitingCallback: isCurrentCaseAwaitingCallback,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }

        fetchData();
        setMedicalQuestionDefinition(medicalHistoryQuestions);
    }, [currentCaseUuid]);

    if (!currentCase) {
        return (
            <Dimmer>
                <LoadingSpinner />
            </Dimmer>
        );
    }

    const { medicalHistory } = { ...patient };
    const latestManagementOutcomeIndex = reviews.length - 1;
    const latestManagementOutcome = reviews[latestManagementOutcomeIndex];
    const recommendationType = latestManagementOutcome.refer
        ? RemoteModelManagementOutcomes.REFERRAL
        : RemoteModelManagementOutcomes.DISCHARGE;
    const callbackOutcomeCompleted = hasCallbackOutcomeCompleted(callbackOutcome);
    const showRequestCallbackSection = !callbackOutcomeCompleted;
    const isCallbackNeeded = currentCase.isCallbackNeeded || isAwaitingCallback;

    return (
        <>
            <CaseStatusContainer activeStatus={caseStatus} isAwaitingCallback={isAwaitingCallback} />
            <RemoteModelContainer header="Assessment Report">
                <AssessmentReport
                    currentCase={currentCase}
                    patient={patient}
                    recommendationType={recommendationType}
                    isCallbackNeeded={isCallbackNeeded}
                />
            </RemoteModelContainer>
            <RemoteModelContainer header="What happens next">
                <WhatHappensNext recommendationType={recommendationType} isCallbackNeeded={isCallbackNeeded} />
            </RemoteModelContainer>
            {showRequestCallbackSection && (
                <FoldableContainer title="Request a callback with clinician">
                    <RequestCallback caseUuid={currentCaseUuid} isCallbackNeeded={isCallbackNeeded} />
                </FoldableContainer>
            )}
            <FoldableContainer title="Find out more information">
                <FindOutMoreInformation lesions={lesions} />
            </FoldableContainer>

            <FoldableContainer title="Medical History">
                <QuestionsSection
                    questionsDefinition={medicalQuestionDefinition}
                    questions={medicalHistory}
                    assessmentType={AssessmentTypes.remoteModel}
                />
            </FoldableContainer>
            <FoldableContainer title="Lesions">
                <Lesions lesions={lesions} organisationUuid={organisationUuid} />
            </FoldableContainer>
        </>
    );
};

export default ReportAvailable;
