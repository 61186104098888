import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { Link, useHistory } from "react-router-dom";
import { InputLabel, TextField, Select, MenuItem, Button } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import InputDateField from "components/InputDateField";
import PatientLookupFormControl from "components/PatientLookup/PatientLookupFormControl";
import PatientLookupDialogue from "components/PatientLookup/PatientLookupForm/PatientLookupDialogue";
import FormHeader from "components/PatientLookup/PatientLookupForm/PatientLookupFormHeader";
import CombinationControl from "components/PatientLookup/PatientLookupForm/PatientLookupFormCombinationControl";

import * as patientService from "services/patientService";

import {
    IPatientLookupForm,
    PDSFields,
    Gender,
    INITIAL_PATIENT_LOOKUP_PARAMS,
    PatientLookupRender,
    IPatientLookupMatched,
    IPDSPatientLookupResponse,
} from "model/patientLookup";
import { IDefinedPersonalData } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";
import PDSIntegrationResponse from "model/pdsResponses";
import { ICase } from "model/case";

import { getCurrentCase, getPatient } from "redux/selectors/assessment";
import { getOrganisation } from "redux/selectors/data";
import { getStringWithoutBackspaces } from "helpers/patientUtils";
import { doesStringContainBackspace, formatPostcode } from "helpers/patientSearch";
import createDropdownOptions from "helpers/semanticUi";

import { mapSavedPatientToFormData, savedPatientDetailsMatchPDSResponse } from "services/patientLookupService";
import { NEW_ASSESSMENT, PATIENT_DETAILS } from "navigation/routes";
import delay from "util/delay";

const MAXIMUM_AGE_DATE = moment().subtract(120, "years");
const MAX_SEARCH_ATTEMPTS = 5;

interface IPatientLookupFormProps {
    combination: number;
    setCombination: (combination: number) => void;
    setMatchedPatient: (matchedPatient: IPatientLookupMatched) => void;
    defaultSearchParams: any;
    setDefaultSearchParams: (params: IPatientLookupForm) => void;
    searchAttempts: number;
    handleSearchAttempt: () => void;
    setIsSupersededNhsNumber: (state: boolean) => void;
    setPatientLookupRender: (PatientLookupRender) => void;
    case: ICase;
    handlePatientMatchContinue: (patientDetails: IPatientLookupMatched | undefined) => void;
    continueWithoutVerifying: () => void;
}
export default function PatientLookupForm({
    combination,
    setCombination,
    setMatchedPatient,
    defaultSearchParams,
    setDefaultSearchParams,
    searchAttempts,
    handleSearchAttempt,
    setIsSupersededNhsNumber,
    setPatientLookupRender,
    case: stateCase,
    handlePatientMatchContinue,
    continueWithoutVerifying,
}: IPatientLookupFormProps) {
    const {
        register,
        formState: { errors },
        clearErrors,
        watch,
        setValue,
        getValues,
        control,
        handleSubmit,
    } = useForm<IPatientLookupForm>({
        defaultValues: defaultSearchParams || INITIAL_PATIENT_LOOKUP_PARAMS,
    });
    const isMounted = useRef(true);
    const history = useHistory();
    const patient = useSelector(getPatient);
    const isPDSVerifiedPatient = patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS;
    const isUnverifiedAndHomeInitiated = stateCase.homeInitiated && !isPDSVerifiedPatient;
    const [needsRefinement, setNeedsRefinement] = useState<boolean>(false);
    const [PDSFailedMatch, setPDSFailedMatch] = useState<boolean>(false);
    const [isPatientDeceased, setIsPatientDeceased] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tooManyAttempts, setTooManyAttempts] = useState<boolean>(false);
    const [isNetworkError, setIsNetworkError] = useState<boolean>(false);
    const [highlightInputs, setHighlightInputs] = useState<boolean>(false);
    const [hideCombinationControl, setHideCombinationControl] = useState<boolean>(false);
    const [showEnterDetailsManuallyButton, setShowEnterDetailsManuallyButton] = useState<boolean>(false);
    const [PDSRecordIncomplete, setPDSRecordIncomplete] = useState<boolean>(false);
    const [hasSearchedWithFirstName, setHasSearchedWithFirstName] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const { uuid: caseUuid } = useSelector(getCurrentCase);
    const { minimumAge, definedPersonalData } = useSelector(getOrganisation);
    const MINIMUM_AGE_DATE = moment().subtract(minimumAge, "years");

    const formFields = watch();

    useEffect(() => {
        Object.entries(defaultSearchParams).forEach(([key, value]) => {
            setValue(key as keyof IPatientLookupForm, value as IPatientLookupForm[keyof IPatientLookupForm]);
        });
    }, [defaultSearchParams]);

    useEffect(() => {
        if (searchAttempts >= MAX_SEARCH_ATTEMPTS) {
            setTooManyAttempts(true);
            setShowEnterDetailsManuallyButton(true);
        }
    }, [searchAttempts]);

    useEffect(() => {
        const attemptPDSVerification = async () => {
            if (stateCase?.homeInitiated && !patient?.integrationInfo && searchAttempts === 0 && combination === 1) {
                const savedPatient = mapSavedPatientToFormData(patient.patientData);
                setValue(PDSFields.nhsNumber, savedPatient[PDSFields.nhsNumber]);
                setValue(PDSFields.dateOfBirth, savedPatient[PDSFields.dateOfBirth]);

                const formData = getValues();

                if (isMounted.current) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await onFormSubmit(formData);
                }
            }
        };

        attemptPDSVerification();
        return () => {
            isMounted.current = false;
        };
    }, [stateCase?.homeInitiated, patient?.integrationInfo]);

    useEffect(() => {
        if (formFields.name) {
            setHasSearchedWithFirstName(true);
        }
    }, []);

    const applyPostcodeFormatting = (): null => {
        if (!formFields[PDSFields.postcode]) {
            return null;
        }
        const currentPostcodeValue = formFields[PDSFields.postcode].trim();
        const doesPostcodeHaveBackspace = doesStringContainBackspace(currentPostcodeValue);

        if (!doesPostcodeHaveBackspace) {
            const formattedPostcode = formatPostcode(currentPostcodeValue);
            setValue(PDSFields.postcode, formattedPostcode);
        }

        return null;
    };

    const isPDSResponseMissingData = (PDSResponseData: IPDSPatientLookupResponse): boolean => {
        const personalDataFields: IDefinedPersonalData[] = definedPersonalData.filter(
            ({ removed, isRequestedAtManualCreation, integrationSourceSystem, required }) =>
                !removed &&
                (!!isRequestedAtManualCreation || !!required) &&
                integrationSourceSystem === PatientDetailsIntegration.PDS
        );
        const personalDataPropertyNames = personalDataFields.map((field: IDefinedPersonalData) => field.propertyName);

        let isMissingData = false;
        personalDataPropertyNames.forEach((propName) => {
            if (!Object.prototype.hasOwnProperty.call(PDSResponseData, propName) || !PDSResponseData[propName]) {
                isMissingData = true;
            }
        });

        return isMissingData;
    };

    const resetFormStates = (): void => {
        setTooManyAttempts(false);
        setPDSFailedMatch(false);
        setNeedsRefinement(false);
        setIsPatientDeceased(false);
        setIsNetworkError(false);
        setHighlightInputs(false);
        setHideCombinationControl(false);
        setPDSRecordIncomplete(false);
        setIsSupersededNhsNumber(false);
    };

    const handleShowSuccessMessage = async (): Promise<void> => {
        // handles rendering the success message for patient initiated cases for 1 second before continuing with flow
        setShowSuccessMessage(true);
        setIsLoading(false);
        await delay(1000);
        setShowSuccessMessage(false);
    };

    const onFormSubmit: SubmitHandler<any> = async (data): Promise<void> => {
        resetFormStates();

        setDefaultSearchParams(data);
        setValue(PDSFields.caseUuid, caseUuid);
        const formData = data;
        formData.caseUuid = caseUuid;
        setIsLoading(true);

        if (formData.name) {
            setHasSearchedWithFirstName(true);
        }

        let PDSResponse;
        try {
            PDSResponse = await patientService.searchIntegrationPatientPDS(formData, combination);
            handleSearchAttempt();
        } catch (e) {
            setIsNetworkError(true);
            setIsLoading(false);
            return null;
        }

        const { itkResponseCode, ...pdsResponsePatient } = PDSResponse;
        const savedNonPDSdetails = {};

        if (patient) {
            const filterNonPDSData = patient?.patientData.filter(
                (datum) => !Object.keys(pdsResponsePatient).includes(datum.name)
            );
            filterNonPDSData?.forEach((datum) => {
                savedNonPDSdetails[datum.name] =
                    datum.textValue || datum.dateValue || datum.numberValue || datum.booleanValue;
            });
        }

        if (itkResponseCode) {
            switch (itkResponseCode) {
                case PDSIntegrationResponse.SUCCESS:
                case PDSIntegrationResponse.LOCAL_STORE_DATA:
                    if (pdsResponsePatient[PDSFields.dateOfDeath]) {
                        setIsPatientDeceased(true);
                        setHighlightInputs(true);
                        break;
                    }
                    if (isPDSResponseMissingData(PDSResponse)) {
                        setPDSRecordIncomplete(true);
                        break;
                    }
                    if (
                        !isPDSVerifiedPatient &&
                        stateCase?.homeInitiated &&
                        searchAttempts === 0 &&
                        savedPatientDetailsMatchPDSResponse({ ...pdsResponsePatient }, patient)
                    ) {
                        await handleShowSuccessMessage();
                        setMatchedPatient({ ...pdsResponsePatient, ...savedNonPDSdetails });
                        handlePatientMatchContinue({ ...pdsResponsePatient, ...savedNonPDSdetails });
                        break;
                    }
                    setShowSuccessMessage(false);
                    setPatientLookupRender(PatientLookupRender.MATCH);
                    setMatchedPatient({ ...pdsResponsePatient, ...savedNonPDSdetails });
                    break;
                case PDSIntegrationResponse.SUPERSEDING_NHS_NO:
                    if (formData.nhs !== PDSResponse[PDSFields.nhsNumber]) {
                        setIsSupersededNhsNumber(true);
                    }
                    setPatientLookupRender(PatientLookupRender.MATCH);
                    setMatchedPatient({ ...pdsResponsePatient, ...savedNonPDSdetails });
                    break;
                case PDSIntegrationResponse.MULTIPLE_MATCHES:
                    setNeedsRefinement(true);
                    setHideCombinationControl(true);
                    if (formData.name) {
                        setShowEnterDetailsManuallyButton(true);
                    }
                    break;
                default:
                    setPDSFailedMatch(true);
                    setHighlightInputs(true);
                    break;
            }
        } else {
            setPDSFailedMatch(true);
        }
        setIsLoading(false);
        return null;
    };

    const validateBirth = (value: string): boolean | string => {
        const inputDate = moment(value);
        if (!inputDate.isValid()) {
            return "Enter a valid date of birth for the patient";
        }

        if (inputDate.isAfter(MINIMUM_AGE_DATE)) {
            return `Minimum age to use this service is ${minimumAge} years old`;
        }

        if (inputDate.isBefore(MAXIMUM_AGE_DATE)) {
            return `Maximum age to use this service is 120 years old`;
        }

        return true;
    };

    const multiplyByPosition = (digit: string, index: number): number => +digit * (11 - (index + 1));
    const addTogether = (previousValue: number, currentValue: number): number => previousValue + currentValue;

    const validateNHSNumber = (nhsNumberInput: string): boolean | string => {
        let nhsNumber = nhsNumberInput;

        nhsNumber = getStringWithoutBackspaces(nhsNumber);
        if (nhsNumber.length !== 10) {
            return "Patient’s NHS number must be 10 characters";
        }
        const nhsNumberAsArray = nhsNumber.split("");
        const remainder = nhsNumberAsArray.slice(0, 9).map(multiplyByPosition).reduce(addTogether, 0) % 11;

        let checkDigit = 11 - remainder;
        const providedCheckDigit = nhsNumberAsArray[9];

        if (checkDigit === 11) {
            checkDigit = 0;
        }

        if (checkDigit !== Number(providedCheckDigit) || checkDigit === 10) {
            return "Invalid NHS number";
        }

        return true;
    };

    const getDobInputController = (): JSX.Element => (
        <>
            <Controller
                control={control}
                name="birth"
                rules={{
                    required: {
                        value: true,
                        message: "Enter the patient’s date of birth",
                    },
                    validate: validateBirth,
                }}
                render={({ field: { onChange, value } }) => (
                    <InputDateField
                        className="date-picker"
                        value={value}
                        onChange={onChange}
                        label="Date of birth"
                        maxDate={MINIMUM_AGE_DATE}
                        minDate={MAXIMUM_AGE_DATE}
                        openTo="year"
                        dateError={!!errors.birth}
                        required
                    />
                )}
            />
            {errors.birth && <p className="errors">{errors.birth.message}</p>}
        </>
    );

    const handleBackButton = (): void => {
        if (patient?.uuid) {
            if (patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS) {
                setPatientLookupRender(PatientLookupRender.SAVED_DETAILS);
            } else {
                history.push(PATIENT_DETAILS);
            }
        } else {
            history.push(NEW_ASSESSMENT);
        }
    };

    const isNotLoadingAndDoNotShowSuccessMessage = !isLoading && !showSuccessMessage;

    const allowContinueWithoutVerifying =
        patient?.uuid && patient?.integrationInfo?.integrationName !== PatientDetailsIntegration.PDS;

    const continueWithoutPDSButton = allowContinueWithoutVerifying ? (
        <Button className="full-width-button" onClick={continueWithoutVerifying} variant="contained" color="primary">
            Proceed without verifying patient details
        </Button>
    ) : (
        <Button
            className="full-width-button"
            component={Link}
            to={{
                pathname: "/patient-details",
                state: {
                    from: "patient-lookup",
                    values: {
                        ...formFields,
                        birth: formFields.birth ? moment(formFields.birth).format("YYYY-MM-DD") : null,
                    },
                },
            }}
            variant="contained"
            color="primary"
        >
            Enter details manually
        </Button>
    );

    return (
        <div className="patient-search">
            <FormHeader
                isLoading={isLoading}
                isTooManyAttempts={tooManyAttempts}
                showSuccessMessage={showSuccessMessage}
                isUnverifiedAndHomeInitiated={isUnverifiedAndHomeInitiated}
            />
            {isNotLoadingAndDoNotShowSuccessMessage ? (
                <PatientLookupDialogue
                    needsRefinement={needsRefinement}
                    PDSFailedMatch={PDSFailedMatch}
                    isPatientDeceased={isPatientDeceased}
                    isTooManyAttempts={tooManyAttempts}
                    formFields={formFields}
                    isNetworkError={isNetworkError}
                    PDSRecordIncomplete={PDSRecordIncomplete}
                    searchAttempts={searchAttempts}
                    continueWithoutVerifying={continueWithoutVerifying}
                />
            ) : null}
            {isNotLoadingAndDoNotShowSuccessMessage ? (
                <>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className={`form-fields ${highlightInputs ? "input-error" : ""}`}>
                            {combination === 2 && (needsRefinement || hasSearchedWithFirstName) && !tooManyAttempts && (
                                <>
                                    <InputLabel required>First name</InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        {...register("name", {
                                            required: { value: true, message: "Enter the patients first name" },
                                        })}
                                    />
                                    {errors.name && <p className="errors">{errors.name.message}</p>}
                                </>
                            )}
                            {combination === 1 && (
                                <>
                                    <InputLabel required>NHS number</InputLabel>
                                    <InputMask
                                        mask="999 999 9999"
                                        maskChar=""
                                        name="nhs"
                                        value={formFields.nhs || ""}
                                        {...register("nhs", {
                                            required: {
                                                value: true,
                                                message: "Enter the patient’s NHS number",
                                            },
                                            validate: validateNHSNumber,
                                        })}
                                    >
                                        {() => (
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                placeholder="NHS number"
                                                {...register("nhs", { validate: validateNHSNumber })}
                                                error={!!errors?.nhs?.message}
                                            />
                                        )}
                                    </InputMask>
                                    {errors.nhs && <p className="errors">{errors.nhs.message}</p>}
                                    {getDobInputController()}
                                </>
                            )}
                            {combination === 2 && (
                                <>
                                    <InputLabel required>Surname</InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        placeholder="Surname"
                                        {...register("surname", {
                                            required: "Enter the patient’s surname",
                                            minLength: {
                                                value: 2,
                                                message: "Patient’s surname must be at least two characters",
                                            },
                                        })}
                                        error={!!errors?.surname?.message}
                                    />
                                    {errors.surname && <p className="errors">{errors.surname.message}</p>}
                                    {getDobInputController()}
                                    <InputLabel required>Gender</InputLabel>
                                    <Controller
                                        control={control}
                                        name="gender"
                                        rules={{
                                            required: { value: true, message: "Select the patient’s gender" },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id="gender"
                                                type="text"
                                                variant="outlined"
                                                value={value || ""}
                                                onChange={onChange}
                                                error={!!errors?.gender?.message}
                                                displayEmpty
                                                renderValue={() => Gender[value] || <span>Select an option</span>}
                                            >
                                                {createDropdownOptions(Gender).map((option) => (
                                                    <MenuItem key={option.key} value={option.key}>
                                                        {option.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.gender && <p className="errors">{errors.gender.message}</p>}

                                    <InputLabel required>Post code</InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        placeholder="Post code"
                                        {...register(PDSFields.postcode, { required: "Enter the patient’s post code" })}
                                        onBlur={applyPostcodeFormatting}
                                        error={!!errors?.postCode?.message}
                                        inputProps={{
                                            maxLength: 8,
                                        }}
                                    />
                                    {errors.postCode && <p className="errors">{errors.postCode.message}</p>}
                                </>
                            )}
                            {!showEnterDetailsManuallyButton ? (
                                <PatientLookupFormControl
                                    button1Name="Back"
                                    button1OnClick={handleBackButton}
                                    button2Name="Search"
                                    button2Type="submit"
                                />
                            ) : null}
                        </div>
                    </form>
                    {!hideCombinationControl && !tooManyAttempts ? (
                        <CombinationControl
                            combination={combination}
                            setCombination={setCombination}
                            formFields={formFields}
                            clearErrors={clearErrors}
                            continueWithoutVerifying={continueWithoutVerifying}
                        />
                    ) : null}
                    {showEnterDetailsManuallyButton ? continueWithoutPDSButton : null}
                </>
            ) : null}
        </div>
    );
}
