export default {
    UPDATE_LESION_NUMBER: "UPDATE_LESION_NUMBER",
    CHECK_TEST: "CHECK_TEST",
    ERROR_GENERAL: "ERROR_GENERAL",
    LOGIN_FAIL: "LOGIN_FAIL",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_FAIL: "LOGOUT_FAIL",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    REFRESH_AUTH_TOKEN: "REFRESH_AUTH_TOKEN",
    SET_TOKEN: "SET_TOKEN",
    MFA_STATUS_REQUEST: "MFA_STATUS_REQUEST",
    MFA_STATUS_REQUEST_SUCCESS: "MFA_STATUS_REQUEST_SUCCESS",
    MFA_STATUS_REQUEST_ERROR: "MFA_STATUS_REQUEST_ERROR",
    MFA_VERIFICATION_SENT: "MFA_VERIFICATION_SENT",
    MFA_VERIFIED: "MFA_VERIFIED",
    MFA_COMPLETE_SETUP: "MFA_COMPLETE_SETUP",
    INCREMENT_STEP: "INCREMENT_STEP",
    DECREMENT_STEP: "DECREMENT_STEP",
    INIT_MOBILE_NUMBER_CHANGE: "INIT_MOBILE_NUMBER_CHANGE",

    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
    CHANGE_PASSWORD_RESET: "CHANGE_PASSWORD_RESET",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",

    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    UPDATE_PASSWORD_SHOW_PROMPT: "UPDATE_PASSWORD_SHOW_PROMPT",
    UPDATE_PASSWORD_RESET: "UPDATE_PASSWORD_RESET",

    CLEAR_ASSESSMENT: "CLEAR_ASSESSMENT",
    CLEAR_CASE: "CLEAR_CASE",
    CLEAR_CASES: "CLEAR_CASES",
    CLEAR_DATA: "CLEAR_DATA",
    CLEAR_PASSWORD_RESPONSE: "CLEAR_PASSWORD_RESPONSE",

    CAPTURE_IMAGE_NEXT_STEP: "CAPTURE_IMAGE_NEXT_STEP",
    CAPTURE_IMAGE_RESET: "CAPTURE_IMAGE_RESET",
    CREATE_CASE_FAIL: "CREATE_CASE_FAIL",
    CREATE_CASE_SUCCESS: "CREATE_CASE_SUCCESS",
    CREATE_DIAGNOSIS_FAIL: "CREATE_DIAGNOSIS_FAILED",
    CREATE_DIAGNOSIS_SUCCESS: "CREATE_DIAGNOSIS_SUCCESS",
    CREATE_LESION_FAIL: "CREATE_LESION_FAIL",
    CREATE_LESION_SUCCESS: "CREATE_LESION_SUCCESS",
    CREATE_PATIENT_FAIL: "CREATE_PATIENT_FAILED",
    CREATE_PATIENT_SUCCESS: "CREATE_PATIENT_SUCCESS",
    GET_CASE: "GET_CASE",
    GET_CASES: "GET_CASES",
    SHOW_ORGANISATION_SELECTION: "SHOW_ORGANISATION_SELECTION",
    HIDE_ORGANISATION_SELECTION: "HIDE_ORGANISATION_SELECTION",
    GET_MY_ORGANISATION_FAIL: "GET_MY_ORGANISATION_FAIL",
    GET_MY_ORGANISATION_REQUEST: "GET_MY_ORGANISATION_REQUEST",
    GET_MY_ORGANISATION_SUCCESS: "GET_MY_ORGANISATION_SUCCESS",
    DISPLAY_CASE_LIST_SNACKBAR: "DISPLAY_CASE_LIST_SNACKBAR",
    HIDE_CASE_LIST_SNACKBAR: "HIDE_CASE_LIST_SNACKBAR",
    GET_REPORT: "GET_REPORT",
    SET_USER_DATA: "SET_USER_DATA",
    GET_USER_ME_FAIL: "GET_USER_ME_FAIL",
    GET_USER_ME_REQUEST: "GET_USER_ME_REQUEST",
    GET_USER_ME_SUCCESS: "GET_USER_ME_SUCCESS",
    SAVE_PATIENT: "SAVE_PATIENT",
    SET_CASE: "SET_CASE",
    SET_CASES: "SET_CASES",
    SET_CASE_LOCATION: "SET_CASE_LOCATION",
    SET_NON_SKIN_CANCER: "SET_NON_SKIN_CANCER",
    SET_CONSENT: "SET_CONSENT",
    SET_DEVICES: "SET_DEVICES",
    SET_STUDIES: "SET_STUDIES",
    SUCCESS_GENERAL: "SUCCESS_GENERAL",
    UPDATE_DIAGNOSIS_SUCCESS: "UPDATE_DIAGNOSIS_SUCCESS",
    WARNING_GENERAL: "WARNING_GENERAL",
    UPDATE_USER_PREFERENCES: "UPDATE_USER_PREFERENCES",
    UPDATE_HIDE_GTE_POPUP: "UPDATE_HIDE_GTE_POPUP",
    HIDE_USER_MODAL_PREFERENCE: "HIDE_USER_MODAL_PREFERENCE",

    CLEAN_FORM_ERROR: "CLEAN_FORM_ERROR",
    CLEAN_VALIDATION_ERROR: "CLEAN_VALIDATION_ERROR",
    FORM_ERROR: "FORM_ERROR",
    CLEAN_ERRORS: "CLEAN_ERRORS",

    CONTINUE_ASSESSMENT: "CONTINUE_ASSESSMENT",
    SET_MANUAL_UPLOAD_REMOTE: "SET_MANUAL_UPLOAD_REMOTE",

    SET_PENDING_REQUEST: "SET_PENDING_REQUEST",
    UPDATE_LESION_SUCCESS: "UPDATE_LESION_SUCCESS",
    SET_CURRENT_LESION: "SET_CURRENT_LESION",

    USER_CREATION: "USER_CREATION",
    USER_CREATION_SUCCESS: "USER_CREATION_SUCCESS",
    USER_CLEAR_DATA: "USER_CLEAR_DATA",
    USER_PERSONAL_DATA_SUCCESS: "USER_PERSONAL_DATA_SUCCESS",
    USER_SET_EMAIL: "USER_SET_EMAIL",
    SET_ORG_UUID: "SET_ORG_UUID",
    SET_ORG_FLOW_TYPE: "SET_ORG_FLOW_TYPE",

    SET_IS_MODAL_CONFIRMATION_VISIBLE: "SET_IS_MODAL_CONFIRMATION_VISIBLE",
    SET_IS_MODAL_EMAIL_CONFIRMATION_VISIBLE: "SET_IS_MODAL_EMAIL_CONFIRMATION_VISIBLE",
    SET_IS_MODAL_REDIRECT_VISIBLE: "SET_IS_MODAL_REDIRECT_VISIBLE",
    SET_IS_PREAUTHORISATION_NUMBER_ERROR_MODAL_VISIBLE: "SET_IS_PREAUTHORISATION_NUMBER_ERROR_MODAL_VISIBLE",

    TOGGLE_SHOW_INTEGRATION_PATIENT_CREATED_MODAL: "TOGGLE_SHOW_INTEGRATION_PATIENT_CREATED_MODAL",
    SET_RESET_PASSWORD_MODAL_TYPE: "SET_RESET_PASSWORD_MODAL_TYPE",
};
