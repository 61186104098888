import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import "scss/Maintenance.scss";

interface IMaintenanceAdvancedWarning {
    isPublic: boolean;
}

const MaintenanceAdvancedWarning = ({ isPublic }: IMaintenanceAdvancedWarning) => {
    const [maintenanceData, setMaintenanceData] = useState<any>(null);

    useEffect(() => {
        // Data is outside of src folder so must use Fetch the retrieve maintenance data from the public folder
        fetch("/maintenance.json")
            .then((response) => response.json())
            .then((data) => setMaintenanceData(data))
            .catch((error) => console.error("Error fetching maintenance data:", error));
    }, []);

    if (!maintenanceData || !isPublic) {
        return null;
    }

    const { maintenanceDate, startTime, endTime, isMaintenancePlanned } = maintenanceData;

    if (!isMaintenancePlanned || !maintenanceDate || !startTime || !endTime) {
        return null;
    }

    const currentDate = new Date().toLocaleDateString();
    const displayDate =
        maintenanceDate === currentDate ? (
            <strong>TODAY</strong>
        ) : (
            <span>
                on <strong>{maintenanceDate}</strong>
            </span>
        );
    const message = (
        <span>
            Skin Analytics will be performing routine maintenance {displayDate} between the hours of{" "}
            <strong>{startTime}</strong> and <strong>{endTime}</strong>. The platform will be unavailable during this
            time. We apologise for any inconvenience this may cause.
        </span>
    );

    return <Box className="planned-maintenance-header">{message}</Box>;
};

export default MaintenanceAdvancedWarning;
