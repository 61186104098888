import React, { FC, useState, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputOnChangeData, Radio, RadioProps, Image } from "semantic-ui-react";

import ICaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import CustomButton from "components/templates/Button";
import ErrorMessage from "components/ErrorMessage";
import RemoteModelContainer from "components/templates/RemoteModelContainer";

import { isUndefined } from "helpers/common";
import { getIsReferCaseCallbackRequired } from "helpers/caseList";

import { CaseStatus } from "model/caseStatus";
import { ModalIconAltTexts } from "model/modal";
import { RemoteTimeline } from "model/remoteCaseTimeline";

import { history } from "App";
import { REMOTE_MODEL_CASES_REPORT } from "navigation/remoteModelRoutes";

import { getAssessment } from "redux/selectors/assessment";

import * as caseService from "services/caseService";

import "scss/RemoteModel.scss";

const FEEDBACK_NAME = "feedback";
const RATING_NAME = "rating";

interface IForm {
    rating?: number;
    feedback?: string;
}

interface IRating {
    value: number;
    key: number;
}

const initialState = {
    rating: undefined,
    feedback: "",
};

let ratings: IRating[] = [];
for (let i = 0; i <= 10; i += 1) {
    ratings = [...ratings, { key: i, value: i }];
}

const CaseInReview: FC = () => {
    const [form, setForm] = useState<IForm>(initialState);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const assessment = useSelector(getAssessment);
    const dispatch = useDispatch();

    const { case: currentCase } = assessment;
    const { uuid: currentCaseUuid, caseStatus, feedback: caseFeedback } = currentCase;

    useEffect(() => {
        dispatch(caseService.getCaseForAssessment(currentCaseUuid));
    }, [currentCaseUuid]);

    useEffect(() => {
        if (caseFeedback) {
            setSubmitted(true);
        }
    }, [caseFeedback?.uuid]);

    if (caseStatus === CaseStatus.CASE_COMPLETED) {
        const isReferCaseCallbackRequired = getIsReferCaseCallbackRequired(currentCase);
        if (!isReferCaseCallbackRequired) {
            history.push(REMOTE_MODEL_CASES_REPORT);
        }
    }

    const { rating, feedback } = form;

    const onSubmit = async () => {
        try {
            if (rating) {
                await caseService.createFeedback(currentCaseUuid, rating, feedback);
                setSubmitted(true);
                setError("");
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const onChange = (event: FormEvent<HTMLInputElement>, data: InputOnChangeData | RadioProps) => {
        const { name, value } = data;
        setForm((prevState: IForm) => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <ICaseStatusContainer activeStatus={RemoteTimeline.REVIEW} />
            <RemoteModelContainer header="Case in review">
                <div className="recommend-block__copies">
                    <p className="p">
                        We have received everything we need from you and have notified one of our dermatologists, who
                        will review your case within <b>one working day</b>. Please hold on to the kit until your Skin
                        Analytics report is available.
                    </p>
                    <p className="p">
                        You will be contacted by email or phone once your report is ready - please keep an eye on your
                        inbox (and check that our emails aren&apos;t in your spam folder) or your phone.
                    </p>
                    <p className="p">
                        You can then send the kit back to us within three working days of receiving your results. You
                        can rate your experience with the service so far below, and you&apos;ll also have the
                        opportunity to provide additional feedback in a follow-up survey after receiving your results.
                    </p>
                </div>
            </RemoteModelContainer>
            <RemoteModelContainer>
                <div className="recommend-block">
                    <div className="recommend-block__content">
                        {submitted ? (
                            <div className="recommend-block__submitted">
                                <Image alt={ModalIconAltTexts.SUCCESS} src="/images/icons/icon-okay.svg" />
                                <p className="h2">Thank you for your feedback!</p>
                            </div>
                        ) : (
                            <>
                                <div>
                                    <p className="h2 is-required">
                                        <span aria-required>
                                            Based on your experience so far, how likely are you to recommend this
                                            service to a friend?
                                        </span>
                                    </p>

                                    <div className="rating-block">
                                        <div className="grid">
                                            {ratings.map((item: IRating) => {
                                                const { key, value } = item;
                                                const isChecked = rating === value;

                                                return (
                                                    <Radio
                                                        tabIndex={0}
                                                        name={RATING_NAME}
                                                        id={key}
                                                        key={key}
                                                        label={{
                                                            children: <span>{value}</span>,
                                                        }}
                                                        onChange={onChange}
                                                        checked={isChecked}
                                                        value={value}
                                                        className="rating-radio"
                                                    />
                                                );
                                            })}
                                        </div>

                                        <div className="explanation-text">
                                            <p>Not at all likely</p>
                                            <p>Extremely likely</p>
                                        </div>
                                    </div>

                                    <div className="custom-input">
                                        <p className="p">What is the most important reason for your score?</p>
                                        <Input name={FEEDBACK_NAME} onChange={onChange} value={feedback} />
                                    </div>

                                    <div className="buttons-wrapper">
                                        <CustomButton
                                            disabled={isUndefined(rating)}
                                            type="button"
                                            variant="filled"
                                            action={onSubmit}
                                            text="Submit"
                                        />
                                        {error ? <ErrorMessage errors={error} /> : null}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </RemoteModelContainer>
        </>
    );
};

export default CaseInReview;
